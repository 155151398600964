<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item
        :disabled="fileLoading"
        :loading="fileLoading"
        @click="download(item.file)"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download Asset
        </v-list-item-title>
      </v-list-item>
      <UIFilesUploader :files-formats="allowedExtensionTypeForModule"
                       :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize" multiple
                       v-if="item?.gallery_group_id"
                       :disabled="!abilityToUpload" @onChange="createDesign($event, true)" class="d-flex">
        <v-list-item ripple class="cursor-pointer mb-1" :disabled="!abilityToUpload">
          <v-list-item-title class="text-caption-1">
            <IconAddNewItem width="14" class="mr-2 gray-60--text"/>
            Upload Fix/New Version
          </v-list-item-title>
        </v-list-item>
      </UIFilesUploader>
      <v-list-item
        v-if="item?.status === $config.project.status.in_review"
        :disabled="checkIfDisabled($config.project.status.rejected) || !abilityToReview || !item?.file"
        @click="approveOrReject($config.project.status.rejected)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCancel width="14" class="mr-2 gray-60--text"/>
          Request adjustments
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="item?.status === $config.project.status.in_review"
        :disabled="checkIfDisabled($config.project.status.approved) || !abilityToReview || !item?.file"
        @click="approveOrReject($config.project.status.approved)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCheck width="14" class="mr-2 gray-60--text"/>
          Approve design
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu.vue";
import UIFilesUploader from "@/components/UI/UIFilesUploader.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import {getFileExtension} from "@/utils/helpers";
import {mapGetters} from "vuex";

export default {
  name: "GlobalDashboardInnerMenu",
  components: {
    UiBtn,
    UIFilesUploader,
    UiMenu,
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconAddNewItem: () => import('@/components/icons/IconAddNewItem'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconCheck: () => import('@/components/icons/IconCheck'),
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    module: {
      type: Object,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      fileLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getProjectPermissionData',
      'getAuthId',
    ]),
    allowedExtensionTypeForModule() {
      if(this.module?.slug === this.$config.project.modules.cadDesign && this.item.file) {
        const idx = this.$config.project.modulesFilesFormatGroup['cad-design'].findIndex(types => types.includes(getFileExtension(this.item?.file?.original_name)))
        return this.$config.project.modulesFilesFormatGroup['cad-design'][idx]
      } else  {
        return this.$config.project.modulesFileFormats[this.module?.slug]
      }
    },
    abilityToUpload() {
      const project_id = this.project.id;
      const activeModule = this.module;
      return this.getPermission(project_id).design['can-upload-file-design'] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    abilityToReview() {
      if (!this?.item) return false;
      // const activeFile = this.file || this.getActiveFile;
      return this.item?.request_reviews?.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner;
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.project.id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    async download(file) {
      if (this.fileLoading || !file) return;
      this.fileLoading = true;
      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    async createDesign(files, newVersion = true) {

      this.fileLoading = true;
      this.$emit('setGlobalLoading', true);
      let formData = new FormData();
      if (this.module.slug === this.$config.project.modules.cadDesign && !newVersion) {
        const cadAllowedType = [...this.$config.project.modulesFilesFormatGroup['cad-design'][1]]
        const cadFiles = []

        files.forEach(file => {

          if (cadAllowedType.includes(getFileExtension(file.name))) {
            cadFiles.push(file)
          }
        })

        Object.values(this.item.style.grouped_items).forEach(([file]) => {
          if (cadAllowedType.includes(getFileExtension(file.file.original_name))) {
            cadFiles.push(file)
          }
        })

        if (cadFiles.length > 1) {
          await this.$store.dispatch('openModal', {
            modalName: 'fileUploadErrorModal',
            data: {
              type: 'limitError',
            }
          });
          this.fileLoading = false;
          return
        }
      }


      files.forEach((file, idx) => {
        formData.append(`files[${idx}][file]`, file);
        formData.append(`files[${idx}][style_id]`, this.item.style.id);

        this.project.tag.forEach(tag => {
          formData.append(`files[${idx}][tags][]`, tag.id);
        });
      });

      if (newVersion && this.item.gallery_group_id) {
        formData.append('gallery_group_id', this.item.gallery_group_id)
      }

      try {
        await this.$api.projectModuleDesign.create(this.project.id, this.module.id, formData);
        this.$emit('updateData')
        this.$eventBus.$emit('updateQuickFilterStatuses');
        // const newFiles = res.data[res.data.length - 1].items.map(item => ({
        //   ...item,
        //   request_reviews: []
        // })).filter(file => file.project_module.id === +this.$route.query.module_id)
        // const newFile = newFiles[0];
        // this.$store.dispatch('setActiveStyle', {
        //   ...res.data[res.data.length - 1],
        //   items: newFiles
        // });

        // this.$store.dispatch('setActiveFile', newFile);

        // this.$router.$updateQueryParams({file_id: newFile.id});

        // if (!newVersion || !this.$route.query.gallery_group_id) {
        //   this.$router.$updateQueryParams({gallery_group_id: newFile.gallery_group_id});
        // }
      } catch (error) {
        console.error(error);
      } finally {
        this.fileLoading = false;
        this.$emit('setGlobalLoading', false);
        // we need to load the project to check if the module has files
        // if module don't have any files, it will be available to remove
        // const project = await this.$api.project.get(this.getProject.id);
        // this.$store.dispatch('setProject', project.data);
        // this.$store.dispatch('setDesignModules', project.data.modules);
      }
    },
    checkIfDisabled(status) {
      if (!this.item) return true;
      // const activeFile = this.file || this.getActiveFile;
      return this?.item?.status === status;
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: [this.item],
          project: this.project
        },
        handlers: {
          onUpdate: () => {
            this.$emit('updateData')
          }
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
