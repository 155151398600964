import Vue from 'vue'
import axios from 'axios'
import config from '../config'
import store from '../store'
import router from "../router";

// Import api sections
import Auth from '@/api/_auth';
import Category from '@/api/_category';
import Filesystem from '@/api/_filesystem';
import Module from '@/api/_module';
import Notification from '@/api/_notification';
import Project from '@/api/_project';
import Tag from '@/api/_tag';
import Task from '@/api/_task';
import Comment from '@/api/_comment';
import TaskComment from '@/api/_task-comment';
import User from '@/api/_user';
import WorkflowScheme from '@/api/_workflow-scheme';
import Template from '@/api/_template';
import MoodBoard from '@/api/_mood-board';
import ProjectModuleDesign from '@/api/_project-module-design';
import Spaces from '@/api/_spaces';
import Folder from '@/api/_folder';
import Dashboard from '@/api/_dashboard';
import Analytics from '@/api/_analytics';

// Create new axios instance
const axiosApi = axios.create({
  baseURL: config.axios.baseURL,
  // timeout: config.axios.timeout,
});

// Check if user authorization add JWT token to routs
axiosApi.interceptors.request.use(config => {
  const accessToken = store.getters['getAuth'];

  if (accessToken) {
    config.headers.common.Authorization = `Bearer ${accessToken.token}`;
  }

  return config;
})

// Listen response and validate status
axiosApi.interceptors.response.use(
  response => {
    return response.data
  }, error => {

    // Check if user not authorization
    if (!error.config.skipResponseInterceptor) {
      switch (error.response.status) {
        case config.http.UNAUTHORIZED:
          if (store.getters['isTempAuth']) {
            store.dispatch('logoutTempAuth', null).then(() => {
              window.location.href = '/'
            })
            return
          }
          Vue.prototype.$ws.closeConnection();
          store.commit('LOGOUT', null);
          router.push({name: 'Login'})
          break;
        case config.http.UNPROCESSABLE_ENTITY:
        case config.http.BAD_REQUEST:
          Vue.$toast.open({
            message: 'Oops! Validation error!',
            type: 'warning',
            position: 'top-right'
          });
          break;
        case config.http.NOT_FOUND:
          Vue.prototype.$ws.closeConnection();
          router.push({name: 'NotFoundPage'});
          break;
        case config.http.FORBIDDEN:
          Vue.prototype.$ws.closeConnection();
          router.push({name: 'ForbiddenPage'});
          break;
        case config.http.INTERNAL_SERVER_ERROR:
          Vue.prototype.$ws.closeConnection();
          router.push({name: 'InternalServerErrorPage'});
          break;
        default:
          Vue.$toast.open({
            message: 'Oops! Something went wrong!',
            type: 'error',
            position: 'top-right'
          });
      }
    }
    return Promise.reject(error);
  });

export default {
  auth: Auth(axiosApi),
  category: Category(axiosApi),
  filesystem: Filesystem(axiosApi),
  module: Module(axiosApi),
  notification: Notification(axiosApi),
  project: Project(axiosApi),
  tag: Tag(axiosApi),
  task: Task(axiosApi),
  comment: Comment(axiosApi),
  taskComment: TaskComment(axiosApi),
  user: User(axiosApi),
  workflowScheme: WorkflowScheme(axiosApi),
  template: Template(axiosApi),
  moodBoard: MoodBoard(axiosApi),
  projectModuleDesign: ProjectModuleDesign(axiosApi),
  spaces: Spaces(axiosApi),
  folder: Folder(axiosApi),
  dashboard: Dashboard(axiosApi),
  analytics: Analytics(axiosApi),
}
