<template>
  <v-dialog
    v-model="getModal('editWorkflowModuleModal').isOpen"
    max-width="328"
  >
    <div class="pa-3 gray-0">

      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Edit Module</h5>
        <UiBtn
          fab
          color="gray-0"
          @click="$store.dispatch('closeModal', 'editWorkflowModuleModal')"
        >
          <IconCancel width="16" class="gray-80--text"/>
        </UiBtn>
      </div>
      <v-divider class="mt-4 mb-5"/>

      <ValidationObserver ref="form" v-slot="{ handleSubmit }">

        <v-form @submit.prevent="handleSubmit(saveAndClose)">
          <v-flex v-if="loading" class="d-flex justify-center align-center">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate/>
          </v-flex>
          <ValidationProvider
            v-else
            class="mb-3"
            mode="eager"
            tag="div"
            name="Module Name"
            rules="required|min:3|max:128"
            v-slot="{ errors }"
          >
            <div class="mb-2 text-body font-weight-light gray-100--text">Module Name</div>
            <v-text-field
              v-model="form.title"
              dense
              outlined
              hide-details
              placeholder="Workflow Name"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>
          <div class="d-flex align-center justify-space-between mb-3">
            <div class="d-flex align-center text-body font-weight-light gray-100--text">
              Status
              <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                    <IconInfoCircle width="16" class="gray-60--text"/>
                  </div>
                </template>
                <span class="gray-0--text">
                  If this is turned on, the module will not need to be reviewed and approved in order to proceed with next modules
                </span>
              </v-tooltip>
            </div>

            <UiSwitch
              class="mt-0 approve-switch"
              v-model="form.approve"
              inset
              color="#6A47E9"
              hide-details
            >
              <template #label>
                <div class="d-flex text-body font-weight-light gray-100--text">
                  All assets auto-approved


                </div>
              </template>
            </UiSwitch>

          </div>
          <div class="d-flex align-center mb-3 text-body font-weight-light gray-100--text">
            <div>Duration in {{ getModal('editWorkflowModuleModal')?.data?.include_weekend ? 'calendar days' : 'work days' }}</div>
            <div class="workflow-ttl-input relative ml-auto flex-shrink-0"
                 :style="{'--value-length': form.ttl.toString().length}">
              <input
                v-model.lazy="form.ttl" v-money="moduleTtlOptions"
                @keyup="() => form.ttl = validateTtl(form.ttl)"
                @keydown.up="incrementTtl(form)"
                @keydown.down="decrementTtl(form)"
              />

              <UiBtn icon plain width="20" height="10" class="absolute px-0"
                     @click="incrementTtl(form)">
                <IconChevronUp width="10"/>
              </UiBtn>
              <UiBtn icon plain width="20" height="10" class="absolute px-0"
                     @click="decrementTtl(form)">
                <IconChevronDown width="10"/>
              </UiBtn>
            </div>
          </div>
          <v-divider class="mt-3 mb-3"/>
          <div class="d-flex justify-space-between">
            <UiBtn
              outlined
              width="144"
              color="gray-30"
              @click="$store.dispatch('closeModal', 'editWorkflowModuleModal')"
              class=""
            >
              <span class="gray-80--text">Cancel</span>
            </UiBtn>
            <UiBtn
              width="144"
              color="accent"
              type="submit"
              :disabled="loading"
            >
              Save Changes
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiSwitch from "@/components/UI/UiSwitch.vue";
import {VMoney} from 'v-money'

export default {
  name: 'EditWorkflowModuleModal',
  directives: {money: VMoney},
  components: {
    UiSwitch,
    UiBtn,
    IconInfoCircle: () => import('@/components/icons/IconInfoCircle'),
    IconChevronUp: () => import('@/components/icons/IconChevronUp'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancel: () => import('@/components/icons/IconCancel'),
  },
  data() {
    return {
      loading: false,
      workflowName: '',
      form: {
        title: '',
        approve: false,
        ttl: ''
      },
      moduleTtlOptions: {
        decimal: "",
        thousands: "",
        suffix: "",
        precision: 0,
        masked: false
      },
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getCategories',
      'getModal',
      'getActiveSpace'
    ]),
  },
  mounted() {
    this.setFormFields()
  },
  methods: {
    async saveAndClose() {
      this.loading = true;
      this.$emit('change', {
        ...this.form,
        moduleTypeSlug: this.getModal('editWorkflowModuleModal')?.data?.module.moduleTypeSlug,
        moduleIdx: this.getModal('editWorkflowModuleModal')?.data?.module.moduleIdx
      })
      await this.$store.dispatch('closeModal', 'editWorkflowModuleModal');
      this.loading = false;
    },
    setFormFields() {
      if(!this.getModal('editWorkflowModuleModal')?.data?.module) {
        return
      }
      this.form.title = this.getModal('editWorkflowModuleModal')?.data?.module.title
      this.form.approve = this.getModal('editWorkflowModuleModal')?.data?.module.approve
      this.form.ttl = this.getModal('editWorkflowModuleModal')?.data?.module.ttl
    },
    formatTtl(ttl) {
      return parseInt(ttl.toString().replace(' Days', ''), 10)
    },
    validateTtl(ttl) {
      const num = parseInt(ttl.replace(' Days', ''), 10);
      if (num === 0) {
        return ttl = '1';
      }
      if (num >= 1000) {
        return ttl = '999';
      }
      return ttl
    },
    incrementTtl(module) {
      const num = this.formatTtl(module.ttl);
      if (num >= 999) return;
      module.ttl = `${num + 1}`;
    },
    decrementTtl(module) {
      const num = this.formatTtl(module.ttl);
      if (num <= 1) return;
      module.ttl = `${num - 1}`;
    },
  }
}
</script>

<style scoped lang="scss">
.approve-switch {
  :deep(.v-input__slot) {
    flex-direction: row-reverse;
    label {
      margin-right: 10px;
    }
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
.workflow-ttl-input {
  input {
    height: 30px;
    padding: 9px;
    width: calc(36px + 14px * var(--value-length, 1));
    border: 1px solid var(--v-gray-30-base);
    outline: none;
    border-radius: 4px;
    font-size: 12px;
    color: var(--v-gray-80-base);
    background: #fff;
  }

  button {
    top: 4px;
    right: 2px;

    &:last-child {
      top: 14px;
    }
  }
}
</style>
