<template>
   <UiListCard
    :item="folder"
    :selectable="false"
    :selected="folder.checked"
    :class="[`project mb-3 relative ${size}`]"
    :to="go(folder)"
  >
    <template #badges>
      <UiBadge color="primary" v-if="folder.is_new">New</UiBadge>
      <UiBadge color="in_review" v-if="folder.review_needed">Review Needed</UiBadge>
      <UiBadge color="revision_needed" v-if="folder.revision_needed">Revision needed</UiBadge>
    </template>
    <template v-slot:image>
      <div class="card-image d-flex align-center justify-center relative">
        <UiSelectedIndicator
          class="absolute mt-2 ml-2 indicator"
          :selected="folder.checked"
          @click.prevent="updateFolder({id: folder.folder_id, checked: !folder.checked})"
        />
        <IconFolder class="folder-icon gray-30--text mb-3"/>
      </div>
    </template>

    <template v-slot:body>
      <div class="project-section-wrap d-flex align-center">
        <div class="project-section px-5 mr-auto">
          <div v-if="size === 'medium'" class="d-grid grid-cols-3auto gap-2 align-center mb-3 text-captions-1">
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <span class="text-body flex-auto font-weight-semi-bold gray-100--text text-truncate" v-bind="attrs"
                      v-on="on">{{ folder.title }}</span>
              </template>
              <span class="white--text font-weight-medium ">{{ folder.title }}</span>
            </v-tooltip>
            <div class="d-flex flex-shrink-0 align-center flex-auto text-no-wrap">
              <IconDoubleChevronRight width="8" class="mr-2 gray-30--text flex-shrink-0"/>
              <span class="gray-60--text">Updated: {{ timeDistance(folder.updated_at) }}</span>
            </div>

            <div class="flex-auto">
              <IconDoubleChevronRight width="8" class="mr-2 gray-30--text"/>
            </div>
          </div>
          <div class="d-grid grid-cols-3auto align-center gap-2" v-if="size === 'medium'">
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-captions-1 text-truncate" v-on="on" v-bind="attrs">
                  By:
                  <UiAvatar :text="folder.user.first_name" size="20" class="ml-1"/>
                  {{ projectOwner(folder) }}
                </div>
              </template>
              <span class="white--text font-weight-medium ">{{ projectOwner(folder) }}</span>
            </v-tooltip>
            <div class="ml-4 text-captions-1 gray-60--text">
              Created: <strong class="font-weight-semi-bold">{{
                format(new Date(folder.created_at), 'dd/MM/yyyy')
              }}</strong>
            </div>
          </div>
          <div class="text-captions-1 text-truncate" v-else>
            By:
            <UiAvatar :text="folder.user.first_name" size="20" class="ml-1"/>
            {{ projectOwner(folder) }}
          </div>

        </div>

        <div v-if="size === 'small'" class="project-section px-6 mr-auto border-left">
          <!-- <div v-if="folder.active_module" class="d-flex align-center">
            <div class="d-flex align-center mr-auto overflow-hidden">
              <UiProjectModuleIcon width="12" :icon="folder.active_module.icon"
                                    class="mr-1 flex-shrink-0 gray-60--text"/>
              <span class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis">{{
                  folder.active_module.title
                }}</span>
            </div>
            <UiProjectStatus block :id="folder.active_module.status" statusType="module" class="flex-shrink-0 ml-2"
                              style="width: 72px"/>
          </div> -->
        </div>

        <div class="project-section px-6 mr-auto border-left">
          <div class="d-flex align-center">
            <div v-if="folder.active_module?.length && size === 'medium'" style="width: 90px">
              <!-- <div class="d-flex align-center mb-2 overflow-hidden">
                <UiProjectModuleIcon width="12" :icon="folder.active_module.icon"
                                      class="mr-1 flex-shrink-0 gray-60--text"/>
                <span class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis">{{
                    folder.active_module.title
                  }}</span>
              </div>
              <UiProjectStatus block :id="folder.active_module.status" statusType="module"/> -->
            </div>

            <div v-if="size === 'small'" class="text-captions-1">
              <div class="text-captions-1 gray-60--text">
                Created: <strong class="font-weight-semi-bold">{{
                  format(new Date(folder.created_at), 'dd/MM/yyyy')
                }}</strong>
              </div>
              <p class="mb-0 gray-60--text">Updated: {{ timeDistance(folder.updated_at) }}</p>
<!--              <p class="mb-0 font-weight-semi-bold">-->
<!--                Due:-->
<!--                <strong class="font-weight-semi-bold gray-100&#45;&#45;text">{{ dueDaysLeft(folder.project_due_date) }}-->
<!--                  {{ dueDaysLeft(folder.project_due_date) === 1 ? 'day' : 'days' }}</strong>-->
<!--              </p>-->
            </div>

            <div class="d-flex align-center ml-auto">
              <UiBtn color="gray-60" icon @click.prevent="goToComment(folder.last_comment_notification_data)" class="mr-2" :disabled="!folder.last_comment_notification_data">
                <v-badge
                  color="accent" dot overlap bordered :value="!!folder.last_comment_notification_data"
                >
                  <IconChat width="16" class="mt-1"/>
                </v-badge>
              </UiBtn>
              <FolderMenu :folder="folder" :loadProjectsAndFolders="loadProjectsAndFolders" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="folder.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
            style="z-index: 2;" @click.prevent.self>
        <v-progress-circular
          :size="48"
          color="accent"
          indeterminate
        />
      </div>
    </template>
  </UiListCard>
</template>

<script>
import {mapGetters} from "vuex";
import UiListCard from "@/components/UI/UiListCard";
// import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiAvatar from "@/components/UI/UiAvatar";
// import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";

import UiBadge from "@/components/UI/UiBadge.vue";
import FolderMenu from "@/components/folder/FolderMenu";
import UiBtn from "@/components/UI/UiBtn.vue";
import IconChat from "@/components/icons/IconChat.vue";

export default {
  name: 'ProjectListCard',
  components: {
    IconChat,
    UiBtn,
    UiBadge,
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconFolder: () => import('@/components/icons/IconFolder'),
    UiListCard,
    UiSelectedIndicator,
    // UiProjectStatus,
    UiAvatar,
    // UiProjectModuleIcon,
    FolderMenu,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    folder: {
      type: Object,
      default: () => {}
    },
    loadProjectsAndFolders: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsList',
    ]),
  },
  methods: {
    format,
    updateFolder(data) {
      if(this.getProjectsList.find(el => !el.is_folder && el.checked)) {
        return
      }
      this.$store.dispatch('updateDashboardProjectFolder', data);
    },
    projectOwner(folder) {
      let lastName = folder.user.last_name ? `${folder.user.last_name.split('')[0]}.` : '';
      return `${folder.user.first_name} ${lastName}`;
    },
    timeDistance(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'dd/MM/yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    dueDaysLeft(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      const dueData = new Date(date).setHours(0, 0, 0, 0)
      return differenceInDays(dueData, now);
    },
    go(folder) {
      return  {name: 'Folder', params: {id: folder.folder_id}};
    },
    goToComment(data) {
      this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.task;

      this.$router.replace({
        name: 'DesignViewPage',
        params: {project_id: data.project_id},
        query: {
          module_id: data.module_id,
          style_id: data.style_id,
          file_id: data.file_id,
          comment_id: data.comment_id,
          task_id: data?.task_id || undefined,
          view_mode: this.$config.project.designModes.task,
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.project {
  ::v-deep .ui-list-card__image {
    padding-bottom: 0;
  }

  .project-section-wrap {
    width: 100%;
    height: 100%;
  }

  .project-section {
    width: 40%;

    &:first-child {
      width: 60%;
    }

    .v-btn.v-btn--disabled {
      opacity: .57;
    }
  }

  &.small {
    //::v-deep .ui-list-card__image {
    //  width: 86px;
    //}

    .project-section {
      width: 32%;

      &:first-child {
        width: 23%;
      }

      &:last-child {
        width: 45%;
      }
    }
  }

  &.is-selected .project-modules-table {
    border-top-color: var(--v-accent-base);
  }

  ::v-deep .project-modules-table {
    border-top: 1px solid var(--v-gray-30-base);

    thead th {
      height: 24px;
      background-color: var(--v-gray-30-base);
      border-bottom: none !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-gray-0-base);
      }
    }

    tbody td {
      border-color: var(--v-gray-10-base) !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-gray-10-base);
      }
    }

  }
}

.border-left::before {
  content: '';
  position: absolute;
  top: 18px;
  bottom: 18px;
  margin-left: -24px;
  border-left: 1px solid var(--v-gray-20-base);
}

.grid-cols-3auto {
  grid-template-columns: 1fr auto 1fr;
}

.indicator {
  top: 0;
  left: 0;
}
.card-image {
  background-color: #fff;
  height: 100%;
}
.folder-icon {
  width: 50%;
}
</style>
