<template>
  <v-menu
    v-model="isMenuOpen"
    :open-on-hover="false"
    bottom
    right
    offset-y
    :nudge-bottom="6"
    :min-width="308"
    :close-on-content-click="false"
    content-class="due-date-menu"
    rounded="sm"
    :disabled="!moduleStyleList?.length"
  >
    <template #activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs" :dueDaysLeft="dueDaysLeftProject" :moduleStyleList="moduleStyleList"/>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(item, index) in moduleStyleList"
        :to="{
            name: 'DesignViewPage',
            params: {project_id: project.id},
            query: {module_id: item.id, style_id: item.style.id}
          }"
        :key="index"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <div class="d-flex align-center mr-1">
                <UiProjectModuleIcon
                  width="14"
                  :icon="item.icon"
                  class="module-icon mr-2 flex-shrink-0"
                />
                <div class="module-title text-truncate">{{item.title}}</div>
              </div>
              <div class="style-title text-captions-1 gray-100--text text-truncate mr-1">{{ item.style.title }}</div>
            </div>
            <div>
              <v-chip
                class="due-date-label"
                :color="dueDaysLeft(item.style.due_date).backgroundColor"
                label
              >
                <span class="text-captions-2" :class="[dueDaysLeft(item.style.due_date).textColor]">{{ dueDaysLeft(item.style.due_date).prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft(item.style.due_date).textColor]">
                  {{ dueDaysLeft(item.style.due_date).value }} {{dueDaysLeft(item.style.due_date).suffix }}
                </span>
              </v-chip>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
<!--      <v-list-group-->
<!--        v-for="(item, index) in moduleStyleList"-->
<!--        v-model="openGroups[index]"-->
<!--        :key="index"-->
<!--        :disabled="readonly"-->
<!--        :no-action="true"-->
<!--        append-icon=""-->
<!--      >-->
<!--        <template v-slot:activator>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title class="d-flex justify-space-between align-center">-->
<!--              <div class="d-flex align-center">-->
<!--                <div class="d-flex align-center mr-1">-->
<!--                  <UiProjectModuleIcon-->
<!--                    width="14"-->
<!--                    :icon="item.icon"-->
<!--                    class="module-icon mr-2 flex-shrink-0"-->
<!--                  />-->
<!--                  <div class="module-title text-truncate">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="style-title text-captions-1 gray-100&#45;&#45;text text-truncate mr-1">{{ item.style.title }}</div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <v-chip-->
<!--                  class="due-date-label"-->
<!--                  :color="dueDaysLeft(item.style.due_date).backgroundColor"-->
<!--                  label-->
<!--                >-->
<!--                  <span class="text-captions-2" :class="[dueDaysLeft(item.style.due_date).textColor]">{{ dueDaysLeft(item.style.due_date).prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft(item.style.due_date).textColor]">-->
<!--                    {{ dueDaysLeft(item.style.due_date).value }} {{dueDaysLeft(item.style.due_date).suffix }}-->
<!--                  </span>-->
<!--                </v-chip>-->
<!--              </div>-->
<!--            </v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </template>-->
<!--        <v-list-item-->
<!--          dense-->
<!--        >-->
<!--          <UIDatePicker-->
<!--            v-model="item.due_date_time"-->
<!--            :disabled="!getPermission(project.id).design['can-change-due-date-design']"-->
<!--            :disabled-date="disabledBeforeToday" @save="save($event, item)"-->
<!--            inline-->
<!--            class="project-date-picker"-->
<!--          />-->
<!--        </v-list-item>-->
<!--      </v-list-group>-->
    </v-list>
  </v-menu>
</template>

<script>
import {differenceInDays, differenceInHours, format} from "date-fns";
// import UIDatePicker from "@/components/UI/UIDatePicker.vue";
import {mapGetters} from "vuex";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon.vue";

export default {
  name: "ModuleStyleDueData",
  components: {
    UiProjectModuleIcon,
    // UIDatePicker,
  },
  data() {
    return {
      isMenuOpen: false,
      // openGroups: {},
      moduleStyleList: []
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
    dueDaysLeftProject() {
      // const now = new Date().setHours(0, 0, 0, 0);
      //
      // const dueData = new Date(this.project.due_date).setHours(0, 0, 0, 0)
      const now = new Date()

      const dueData = new Date(this.project.due_date)
      const daysDiff =  differenceInDays(dueData, now);

      if(daysDiff < 1 && daysDiff > -1) {
        const hoursLeft = differenceInHours(dueData, now);

        return {
          value: hoursLeft === 0 ? 'less than 1' : Math.abs(hoursLeft),
          prefix: `${hoursLeft < 0 ? 'Overdue:' : 'Due:'}`,
          suffix: `${hoursLeft === 0 || Math.abs(hoursLeft) === 1  ? 'hour' : 'hours'}`,
          backgroundColor: 'primary-10',
          textColor: 'error-70--text',
        }
      } else if(daysDiff <= -1) {
        return {
          value: daysDiff * -1,
          prefix: 'Overdue:',
          suffix: daysDiff > 1 ? 'days': 'day',
          backgroundColor: 'error-70',
          textColor: 'white--text',
        }
      }
      return {
        value: daysDiff,
        prefix: 'Due:',
        suffix: daysDiff > 1 ? 'days': 'day',
        backgroundColor: 'primary-10',
        textColor: 'warning-70--text',
      };
    },
  },
  watch: {
    project: {
      handler() {
        this.setModuleStyleList()
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    async save(data, moduleItem) {
      try {
        this.isMenuOpen = false;
        // this.closeAllGroups();
        const res = await this.$api.projectModuleDesign.updateModuleStyleDueDate(this.project.id, moduleItem.id, moduleItem.style.id, {
          due_date: format(data.value, 'yyyy-MM-dd'),
          all_styles_module: false
        })

        const updatedProject = {
          ...this.project,
          due_date: res.data.project_due_date,
          modules: this.project.modules.map(module => {
            if(module.id === moduleItem.id) {
              return {
                ...module,
                styles: module.styles.map(style => {
                  if(style.id === moduleItem.style.id) {
                    return {
                      ...style,
                      due_date: res.data.due_date
                    }
                  }
                  return style
                })
              }
            }
            return module
          })
        }
        this.$toast.open({
          message: `Due date updated`,
          type: 'success',
          position: 'top-right'
        });
        this.$emit('updateData', updatedProject)
      } catch (error) {
        this.setModuleStyleList();
        console.log('error - ', error)
      }
    },
    // disabledBeforeToday(date) {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   return date < today;
    // },
    dueDaysLeft(designDate) {
      // const now = new Date().setHours(0, 0, 0, 0);
      //
      // const dueData = new Date(designDate).setHours(0, 0, 0, 0)
      const now = new Date()

      const dueData = new Date(designDate)
      const daysDiff =  differenceInDays(dueData, now);

      if(daysDiff < 1 && daysDiff > -1) {
        const localDate = new Date(designDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
        return {
          value: format(localDate, 'h:mm a'),
          prefix: 'Due:',
          suffix: '',
          backgroundColor: 'primary-10',
          textColor: 'error-70--text',
        }
      } else if(daysDiff <= -1) {
        return {
          value: daysDiff * -1,
          prefix: 'Overdue:',
          suffix: daysDiff > 1 ? 'days': 'day',
          backgroundColor: 'error-70',
          textColor: 'white--text',
        }
      }
      return {
        value: daysDiff,
        prefix: 'Due:',
        suffix: daysDiff > 1 ? 'days': 'day',
        backgroundColor: 'primary-10',
        textColor: 'warning-70--text',
      };
    },
    // closeAllGroups() {
    //   this.openGroups = {};
    // },
    setModuleStyleList() {
      const designsList = [...this.project.modules].sort((a, b) => a.sort_order - b.sort_order).reduce((acc, module) => {
        const styles = module.styles.reduce((acc, style) => {
          if(module.status !== this.$config.project.moduleStatus.finished && style.status !== this.$config.project.status.approved) {
            acc.push({
              ...module,
              style: style,
              due_date_time: new Date(style.due_date).getTime()
            })
          }
          return acc
        }, []);
        acc.push(...styles)
        return acc;
      }, [])
      designsList.sort((a, b) => a.style.sort_order - b.style.sort_order)
      this.moduleStyleList = designsList
    }
  }
}
</script>

<style scoped lang="scss">
.due-date-menu {
  background: var(--v-gray-30-base);
  padding: 10px;
  max-height: 500px;

  :deep(.v-list) {
    background: var(--v-gray-30-base);
    padding: 0;
    //.v-list-group {
      //background: #fff;
      //border-radius: 2px;
      //
      //&:not(:last-child) {
      //  margin-bottom: 4px;
      //}
      .v-list-item {
        padding: 0;
        background: #fff;
        border-radius: 2px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
      .v-list-item__content {
        padding: 5px 15px;
      }
    //}
    //.v-list-group__items {
    //  .v-list-item {
    //    padding: 0;
    //  }
    //}

  }
}
.due-date-label {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}
.project-date-picker {
  width: 288px;
}
.module-icon {
  color: var(--v-gray-60-base);
}
.module-title {
  max-width: 80px;
  width: 80px;
}
.style-title {
  max-width: 80px;
}
</style>
