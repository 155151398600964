import config from '@/config';
import auth from '@/router/middleware/auth';

export default [
  {
    path: '/global-dashboard-all',
    name: 'GlobalDashboardAll',
    component: () => import(/* webpackChunkName: 'DashboardPage' */ '@/views/globalDashboardAll/GlobalDashboardAllPage'),
    meta: {
      layout: config.layout.app,
      title: 'Global Dashboard All',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/global-dashboard',
    name: 'GlobalDashboard',
    component: () => import(/* webpackChunkName: 'DashboardPage' */ '@/views/globalDashboard/GlobalDashboardPage'),
    meta: {
      layout: config.layout.app,
      title: 'Global Dashboard',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: 'DashboardPage' */ '@/views/dashboard/DashboardPage'),
    meta: {
      layout: config.layout.app,
      title: 'Dashboard',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import(/* webpackChunkName: 'AnalyticsPage' */ '@/views/analytics/AnalyticsPage'),
    meta: {
      layout: config.layout.app,
      title: 'Analytics',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/space/:id/dashboard',
    name: 'Space',
    component: () => import(/* webpackChunkName: 'SpaceDashboard' */ '@/views/dashboard/DashboardPage'),
    meta: {
      layout: config.layout.app,
      title: 'Dashboard',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: 'TasksPage' */ '@/views/tasks/TasksPage'),
    meta: {
      layout: config.layout.app,
      title: 'Tasks',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: 'ChatPage' */ '@/views/ChatPage'),
    meta: {
      layout: config.layout.app,
      title: 'Chat',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/space/:space_id/files',
    name: 'FilesByProjects',
    component: () => import(/* webpackChunkName: 'SpaceFilesByProjects' */ '@/views/files/by-projects/FilesByProjectsPage'),
    meta: {
      layout: config.layout.app,
      title: 'Files',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/space/:space_id/files/project/:project_id',
    name: 'FilesByStyles',
    component: () => import(/* webpackChunkName: 'FilesByStylesPage' */ '@/views/files/by-styles/FilesByStylesPage'),
    meta: {
      layout: config.layout.app,
      title: 'Files',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/space/:space_id/files/project/:project_id/style/:style_id',
    name: 'FilesByVersions',
    component: () => import(/* webpackChunkName: 'FilesByVersionsPage' */ '@/views/files/by-versions/FilesByVersionsPage'),
    meta: {
      layout: config.layout.app,
      title: 'Files',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/space/:space_id/files/all',
    name: 'FilesAll',
    component: () => import(/* webpackChunkName: 'FilesByVersionsPage' */ '@/views/files/by-versions/FilesByVersionsPage'),
    meta: {
      layout: config.layout.app,
      title: 'Files',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: {name: 'GeneralSettings'},
    component: () => import(/* webpackChunkName: 'SettingsPage' */ '@/views/settings/SettingsPage'),
    children: [
      {
        path: 'general',
        name: 'GeneralSettings',
        meta: {
          layout: config.layout.app,
          title: 'General Settings',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'authentication',
        name: 'AuthenticationSettings',
        meta: {
          layout: config.layout.app,
          title: 'Authentication Settings',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'members',
        name: 'MembersSettings',
        meta: {
          layout: config.layout.app,
          title: 'Members Settings',
          middleware: [
            auth
          ]
        }
      },
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: 'TakeOverAccountPage' */ '@/views/admin/TakeOverAccount.vue'),
    meta: {
      layout: config.layout.admin,
      title: 'Admin',
    }
  },
  {
    path: '/folder/:id',
    name: 'Folder',
    component: () => import(/* webpackChunkName: 'FolderPage' */ '@/views/folders/FolderPage'),
    meta: {
      layout: config.layout.app,
      title: 'FolderPage',
      middleware: [
        auth
      ]
    }
  },
]
