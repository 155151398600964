<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_14546_124687)">
      <path d="M5.40625 13.7031C4.32292 13.2031 3.44271 12.4479 2.76562 11.4375C2.08854 10.4271 1.75 9.28646 1.75 8.01562C1.75 7.74479 1.76312 7.47917 1.78937 7.21875C1.81562 6.95833 1.85979 6.70312 1.92188 6.45312L1.20312 6.875L0.578125 5.79688L3.5625 4.07812L5.28125 7.04688L4.1875 7.67188L3.34375 6.20312C3.22917 6.48438 3.14333 6.77604 3.08625 7.07812C3.02917 7.38021 3.00042 7.69271 3 8.01562C3 9.02604 3.27604 9.94542 3.82812 10.7738C4.38021 11.6021 5.11458 12.214 6.03125 12.6094L5.40625 13.7031ZM10.1875 6.125V4.875H11.8906C11.4115 4.28125 10.8333 3.82042 10.1562 3.4925C9.47917 3.16458 8.76042 3.00042 8 3C7.42708 3 6.88542 3.08854 6.375 3.26563C5.86458 3.44271 5.39583 3.69271 4.96875 4.01562L4.34375 2.92188C4.86458 2.55729 5.43229 2.27083 6.04688 2.0625C6.66146 1.85417 7.3125 1.75 8 1.75C8.82292 1.75 9.60938 1.90375 10.3594 2.21125C11.1094 2.51875 11.7812 2.96375 12.375 3.54625V2.6875H13.625V6.125H10.1875ZM9.78125 15.5L6.79688 13.7812L8.51562 10.8125L9.59375 11.4375L8.70312 12.9688C9.93229 12.7917 10.9558 12.2344 11.7738 11.2969C12.5917 10.3594 13.0004 9.26042 13 8C13 7.88542 12.9975 7.77854 12.9925 7.67938C12.9875 7.58021 12.9744 7.47875 12.9531 7.375H14.2188C14.2292 7.47917 14.2371 7.58083 14.2425 7.68C14.2479 7.77917 14.2504 7.88583 14.25 8C14.25 9.40625 13.8306 10.6642 12.9919 11.7738C12.1531 12.8833 11.0621 13.6306 9.71875 14.0156L10.4062 14.4219L9.78125 15.5Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_14546_124687">
        <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconCycle"
}
</script>
