<template>
  <div v-if="loading" class="d-flex justify-center pt-16">
    <v-progress-circular
      :size="100"
      color="accent"
      indeterminate
    />
  </div>
  <div v-else class="dashboard-page d-flex flex-column fill-height">
    <PageHeader :maxContainerWidth="maxContainerWidth" :border="false">
      <div class="d-flex justify-space-between align-center space-x-4">
        <h1 class="text-heading text-truncate">
          Global Dashboard
        </h1>
      </div>
    </PageHeader>

    <v-container :style="{maxWidth: convertToUnit(maxContainerWidth)}">

<!--      <div v-if="false" class="d-flex justify-center pt-16">-->
<!--        <v-progress-circular-->
<!--          :size="100"-->
<!--          color="accent"-->
<!--          indeterminate-->
<!--        />-->
<!--      </div>-->

      <v-row class="pt-5" gutter="20">
        <v-col cols="12" sm="6">
          <StyleSection
            sectionTitle="Asset Pending"
            :count="counters.assetsPending"
            :loading="loadings.assetsPending"
            :viewMoreTo="{
              name: 'GlobalDashboardAll',
              query: {status: $config.project.projectModulesStatus.assetPending}
            }"
          >
            <StyleCard
              v-for="assetPending in assetsPendingList"
              :key="assetPending.id"
              :data="assetPending"
              :getAssetsPending="getAssetsPending"
              :getPendingApproval="getPendingApproval"
              :status="$config.project.projectModulesStatus.assetPending"
              class="mb-2"
            />
            <div v-if="!assetsPendingList?.length" class="d-flex justify-center align-center">No data</div>
          </StyleSection>
        </v-col>
        <v-col cols="12" sm="6">
          <StyleSection
            sectionTitle="Pending Approval"
            :count="counters.pendingApproval"
            :loading="loadings.pendingApproval"
            :viewMoreTo="{
              name: 'GlobalDashboardAll',
              query: {status: $config.project.projectModulesStatus.needReview}
            }"
          >
            <StyleCard
              v-for="pendingApproval in pendingApprovalList"
              :key="pendingApproval.id"
              :data="pendingApproval"
              :getPendingApproval="getPendingApproval"
              :getAssetsPending="getAssetsPending"
              :status="$config.project.projectModulesStatus.needReview"
              class="mb-2"
            />
            <div v-if="!pendingApprovalList?.length" class="d-flex justify-center align-center">No data</div>
          </StyleSection>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <StyleSection
            sectionTitle="New Comments"
            :view-more-action="() => $store.dispatch('toggleNotificationBar', {open: true, type: $config.notifications.notificationType.messages})"
            :count="counters.lastNotifications"
          >
            <v-row v-if="lastNotificationList?.length">
              <v-col v-for="notification in lastNotificationList" :key="notification.id">
                <NotificationCard :notification="notification" @click="navigateToComment(notification)" class="cursor-pointer" />
              </v-col>
            </v-row>
            <div v-else-if="!lastNotificationList?.length" class="d-flex justify-center align-center">No data</div>
          </StyleSection>
        </v-col>
      </v-row>
<!--      <EditModuleAccessModal v-if="getModal('editModuleAccessModal').data.project"/>-->
<!--      $store.dispatch('toggleNotificationBar', {open: true, type: $config.notifications.notificationType.messages})-->
    </v-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import StyleSection from "@/views/globalDashboard/StyleSection.vue";
import StyleCard from "@/views/globalDashboard/StyleCard.vue";
import NotificationCard from "@/views/globalDashboard/NotificationCard.vue";
import {convertToUnit} from "@/utils/helpers";
// import EditModuleAccessModal from "@/components/modals/EditModuleAccessModal.vue";
import {mapGetters} from "vuex";
import notificationLinkMixin from "@/mixins/notificationLinkMixin";
import globalDashboardMixin from "@/mixins/globalDashboardMixin";

export default {
  name: "GlobalDashboardPage",
  mixins: [notificationLinkMixin, globalDashboardMixin],
  components: {
    // EditModuleAccessModal,
    NotificationCard,
    StyleCard,
    StyleSection,
    PageHeader,
  },
  data() {
    return {
      loading: true,
      lastNotificationList: [],
      assetsPendingList: [],
      pendingApprovalList: [],
      counters: {
        lastNotifications: 0,
        pendingApproval: 0,
        assetsPending: 0,
      },
      loadings: {
        lastNotifications: false,
        pendingApproval: false,
        assetsPending: false,
      },
      maxContainerWidth: 1200,
    };
  },
  computed: {
    ...mapGetters([
      'getModal',
    ]),
  },
  async created() {
    await this.$store.dispatch('setActiveSpace', null)
    this.$store.commit('SET_ACTIVE_FOLDER', null)
    await Promise.all([
      this.getLastNotifications(),
      this.getAssetsPending(),
      this.getPendingApproval(),
      this.getPermissions()
    ])

    this.loading = false
  },
  methods: {
    convertToUnit,
    async getLastNotifications() {
      try {
        const res = await this.$api.dashboard.getComments()
        this.lastNotificationList = res.data
        this.counters.lastNotifications = res?.meta?.total > 99 ? '99+' : res?.meta?.total || 0
        return res
      } catch (err) {
        console.error(err)
      }
    },
    async getPendingApproval() {
      try {
        this.loadings.pendingApproval = true;
        const res =  await this.$api.dashboard.getProjectModules({
          count: 4,
          status: this.$config.project.projectModulesStatus.needReview,
          hide_viewed: 1
        })
        this.counters.pendingApproval = res?.meta?.total > 99 ? '99+' : res?.meta?.total || 0
        this.pendingApprovalList = this.transformData(res?.data)
        this.loadings.pendingApproval = false;
        return res
      } catch (err) {
        this.loadings.pendingApproval = false;
        console.error(err)
      }
    },
    async getAssetsPending() {
      try {
        this.loadings.assetsPending = true;
        const res =  await this.$api.dashboard.getProjectModules({
          count: 4,
          status: this.$config.project.projectModulesStatus.assetPending, // TODO define what is this status?
          hide_viewed: 1
        })
        this.counters.assetsPending = res?.meta?.total > 99 ? '99+' : res?.meta?.total || 0
        this.assetsPendingList = this.transformData(res?.data)
        this.loadings.assetsPending = false;
        return res
      } catch (err) {
        this.loadings.assetsPending = false;
        console.error(err)
      }
    },
    async getPermissions() {
      try {
        const permissions = await this.$api.project.permissions();
        await this.$store.dispatch('setPermissions', permissions.data);
      } catch (err) {
        console.error(err)
      }
    },
    navigateToComment(notification) {
      const navigation = this.setNotificationsLink(notification.notification_data)
      if(navigation.callback) {
        navigation.callback();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
