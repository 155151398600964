var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DatePicker',_vm._g(_vm._b({staticClass:"ui-due-date",class:{'ui-due-datepicker ui-due-datepicker-inner': _vm.inline},attrs:{"disabled-date":_vm.disabledDate,"disabled":_vm.disabled,"value":_vm.value,"confirm":!_vm.hideFooter,"clearable":false,"open":_vm.isOpen,"lang":{
    formatLocale: {
      // MMMM
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      // MMM
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      // dddd
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      // ddd
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      // dd
      weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // first day of week
      firstDayOfWeek: 0,
      // first week contains January 1st.
      firstWeekContainsDate: 1
    }
  },"title-format":"YYYY-MM-DD","value-type":"timestamp","popup-class":['ui-due-datepicker', _vm.popupClass],"inline":_vm.inline},on:{"update:open":function($event){_vm.isOpen=$event},"open":_vm.toggleCalendar,"close":_vm.toggleCalendar,"pick":_vm.handleChange},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.hideHeader)?_vm._t("header",function(){return [_c('div',{staticClass:"ui-due-datepicker-header"},[_c('IconClockSolid',{attrs:{"width":"24"}}),_vm._v(" Due date "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.format(_vm.newDueDate, 'MMM dd, yyyy')))])],1)]},{"date":_vm.newDueDate}):_vm._e()]},proxy:true},{key:"input",fn:function(){return [_c('div',{class:{
      'is-open--input' : _vm.isOpen,
      'is-closed--input': !_vm.isOpen,
      'is-disabled--input': _vm.disabled,
    }},[_vm._t("input",function(){return [_c('UiBtn',{staticClass:"due-date-btn text-body text-capitalize font-weight-light",attrs:{"outlined":"","width":"165","color":_vm.daysLeft < 0 ? 'error' : 'success-100'}},[_c('IconClockSolid',{staticClass:"mr-2",attrs:{"width":"20"}}),(_vm.daysLeft < 0)?[_vm._v("Missed due")]:[_vm._v(_vm._s(_vm.daysLeft)+" "+_vm._s(_vm.daysLeft === 1 ? 'day' : 'days')+" left")],_c('IconPen',{staticClass:"ml-4",attrs:{"width":"18"}})],2)]},{"open":_vm.isOpen,"dayLeft":_vm.daysLeft,"value":_vm.value})],2)]},proxy:true},{key:"footer",fn:function(){return [(!_vm.hideFooter)?_vm._t("footer",function(){return [_c('div',{staticClass:"mb-3"},[_c('UiBtn',{staticClass:"flex-shrink-0 cancel-btn",attrs:{"color":"accent","outlined":"","type":"button","width":"78"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('UiBtn',{staticClass:"flex-shrink-0 save-btn",attrs:{"color":"accent","type":"button","width":"98"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)]},{"cancel":_vm.cancel,"save":_vm.save}):_vm._e(),_vm._t("bottom")]},proxy:true}],null,true)},'DatePicker',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }