<template>
  <div
    class="d-flex justify-center align-center bordered-item fill-width rounded accent--text empty-state"
  >
    <IconInfoFilled class="mr-2" />
    <p class="mb-0 text-body gray-100--text font-weight-light">
      Please drag and drop modules here
    </p>
  </div>
</template>
<script>
export default {
  name: 'DragDropEmptyState',
  components: {
    IconInfoFilled: () => import("@/components/icons/IconInfoFilled"),
  },
}
</script>
<style scoped lang="scss">
.bordered-item {
  height: 44px;
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-0-base);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  &.empty-state {
    height: 40px;
    border: 1px dashed var(--v-accent-30-base);
  }
}
</style>
