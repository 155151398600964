<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-model="dateFilter"
      :items="filtersState[$config.analyticsConfig.filter_slugs.metrics_date].data"
      :loading="filtersState[$config.analyticsConfig.filter_slugs.metrics_date].loading"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      :multiple="false"
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-date-metrics"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.metrics_date)"
      @update:search-input="highlightSelection($event, filtersState[$config.analyticsConfig.filter_slugs.metrics_date].data, $config.analyticsConfig.filter_slugs.metrics_date)"
      placeholder="Select Date"
      class="filter-select filter-select-date-metrics"
    >
      <template v-slot:selection="{item}">
        <div class="selected-result d-flex align-center text-captions-1">
          {{ item.title }}
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" @click="onClickDateItem">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex text-captions-1">
                <div v-html="item.htmlTitle || item.title"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.analyticsConfig.filter_slugs.metrics_date)"
          class="d-flex justify-center"
        >
          <v-progress-circular
            v-show="filtersState[$config.analyticsConfig.filter_slugs.metrics_date].loading"
            :size="20"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import UiCheckbox from "@/components/UI/UiCheckbox.vue";
import {highlightText} from "@/utils/helpers";

export default {
  name: "MetricsFilters",
  components: {
    UiCheckbox,
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    filtersState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      debounce: null,
      debounceSearch: null,
    }
  },
  computed: {
    dateFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.analyticsConfig.filter_slugs.metrics_date]: val}})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.metrics_date]
      }
    },
  },
  methods: {
    updateFilter(type) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        if (this.$route.query.page && this.$route.query.page !== '1') {
          await this.$router.replace({query: {...this.$route.query, page: '1'}})
        }
        this.$emit('updateFilter', type)
      }, 800)
    },
    endIntersect(entries, observer, isIntersecting, type) {
      if (isIntersecting && !this.filtersState[type].loading && this.filtersState[type].meta?.total > this.filtersState[type].data.length && !this.filtersState[type].search) {
        this.filtersState[type].page += 1
        this.$emit('loadMoreFilters', {type})
      }
    },
    highlightSelection($event, list, type) {
      this.filtersState[type].search = $event

      list.forEach(item => {
        item.htmlTitle = highlightText($event || '', item.title || '');
      });

      if ($event) {
        clearTimeout(this.debounceSearch)
        this.debounceSearch = setTimeout(() => {
          this.$emit('loadMoreFilters', {type})
        }, 600)
      }
    },
    onClickDateItem() {
      if (this.dateFilter || this.dateFilter === 0) {
        this.dateFilter = undefined
        this.updateFilter(this.$config.analyticsConfig.filter_slugs.metrics_date)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.selected-result {
  padding: 3px 0;
}

.filter-select {
  width: 198px;
  max-width: 198px;

  :deep(.v-select__selections) {
    padding: 2px 0 !important;

    .selected-result {
      &:not(:first-child) {
        display: none !important;
      }
    }

    input {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
</style>
