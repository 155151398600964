<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.97339 4.25269C8.31857 4.25269 8.59839 3.97286 8.59839 3.62769C8.59839 3.28251 8.31857 3.00269 7.97339 3.00269C7.62821 3.00269 7.34839 3.28251 7.34839 3.62769C7.34839 3.97286 7.62821 4.25269 7.97339 4.25269Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.97339 8.62769C8.31857 8.62769 8.59839 8.34786 8.59839 8.00269C8.59839 7.65751 8.31857 7.37769 7.97339 7.37769C7.62821 7.37769 7.34839 7.65751 7.34839 8.00269C7.34839 8.34786 7.62821 8.62769 7.97339 8.62769Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.97339 13.0027C8.31857 13.0027 8.59839 12.7229 8.59839 12.3777C8.59839 12.0325 8.31857 11.7527 7.97339 11.7527C7.62821 11.7527 7.34839 12.0325 7.34839 12.3777C7.34839 12.7229 7.62821 13.0027 7.97339 13.0027Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3484 4.25269C12.6936 4.25269 12.9734 3.97286 12.9734 3.62769C12.9734 3.28251 12.6936 3.00269 12.3484 3.00269C12.0032 3.00269 11.7234 3.28251 11.7234 3.62769C11.7234 3.97286 12.0032 4.25269 12.3484 4.25269Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3484 8.62769C12.6936 8.62769 12.9734 8.34786 12.9734 8.00269C12.9734 7.65751 12.6936 7.37769 12.3484 7.37769C12.0032 7.37769 11.7234 7.65751 11.7234 8.00269C11.7234 8.34786 12.0032 8.62769 12.3484 8.62769Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3484 13.0027C12.6936 13.0027 12.9734 12.7229 12.9734 12.3777C12.9734 12.0325 12.6936 11.7527 12.3484 11.7527C12.0032 11.7527 11.7234 12.0325 11.7234 12.3777C11.7234 12.7229 12.0032 13.0027 12.3484 13.0027Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.59839 4.25269C3.94357 4.25269 4.22339 3.97286 4.22339 3.62769C4.22339 3.28251 3.94357 3.00269 3.59839 3.00269C3.25321 3.00269 2.97339 3.28251 2.97339 3.62769C2.97339 3.97286 3.25321 4.25269 3.59839 4.25269Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.59839 8.62769C3.94357 8.62769 4.22339 8.34786 4.22339 8.00269C4.22339 7.65751 3.94357 7.37769 3.59839 7.37769C3.25321 7.37769 2.97339 7.65751 2.97339 8.00269C2.97339 8.34786 3.25321 8.62769 3.59839 8.62769Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.59839 13.0027C3.94357 13.0027 4.22339 12.7229 4.22339 12.3777C4.22339 12.0325 3.94357 11.7527 3.59839 11.7527C3.25321 11.7527 2.97339 12.0325 2.97339 12.3777C2.97339 12.7229 3.25321 13.0027 3.59839 13.0027Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDotsGrid'
}
</script>
