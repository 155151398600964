<template>
  <UiGridCard
    :item="project"
    :itemStatus="project.status"
    :selectable="false"
    :selected="project.checked"
    :to="go(project)"
  >
    <template #header>
      <div class="d-flex justify-space-between py-3 px-3">
        <UiBtn v-if="project.last_comment_notification_data" color="gray-80" icon @click.prevent="goToComment(project.last_comment_notification_data)" class="ml-n2">
          <v-badge
            color="accent" dot overlap bordered :value="true"
          >
            <IconChat width="16" class="mt-1"/>
          </v-badge>
        </UiBtn>
        <UiSelectedIndicator
          :selected="project.checked"
          @click.prevent="updateProject({id: project.id, checked: !project.checked})"
        />
      </div>
    </template>

    <template #badges>
      <UiBadge color="primary" v-if="project.is_new">New</UiBadge>
      <UiBadge color="in_review" v-if="project.review_needed" @click.stop :to="buildReviewRevisionLink(project, 'item_for_review')">Review Needed</UiBadge>
      <UiBadge color="revision_needed" v-if="project.revision_needed" @click.stop :to="buildReviewRevisionLink(project, 'item_for_revision')">Revision needed</UiBadge>
    </template>
    <template #image>
      <div class="card-image d-flex align-center justify-center flex-grow-1">
        <img class="fill-width fill-height" v-if="project.file" :src="getFileThumbnails(project.file.thumbnails) || project.file.url" alt=""/>
        <UIImage class="fill-width fill-height" v-else :name-path="`default-img.png`"></UIImage>
      </div>
    </template>

    <template #bottom>
      <div class="card-bottom__top mb-1">
        <v-tooltip top color="accent">
          <template v-slot:activator="{ on, attrs }">
            <h6 class="card-bottom__title font-weight-semi-bold text-truncate" v-bind="attrs"
                v-on="on">{{ project.title }}</h6>
          </template>
          <span class="white--text font-weight-medium ">{{ project.title }}</span>
        </v-tooltip>
      </div>
      <div class="card-bottom__content d-flex justify-space-between flex-wrap">
        <div class="card-bottom__content__left">
          <div>Created: {{format(new Date(project.created_at), 'dd/MM/yyyy')}}</div>
          <div class="gray-80--text"><strong>Updated: {{ timeDistance(project.updated_at) }}</strong></div>
        </div>
        <div class="card-bottom__content__right">
          <ModuleStyleDueDate v-if="!isProjectCompleted && project?.due_date" :project="project" @updateData="updateProject($event)">
            <template v-slot:activator="{attrs, on, dueDaysLeft }">
              <v-btn
                class="due-date-btn"
                :color="dueDaysLeft.backgroundColor"
                v-bind="attrs"
                v-on="on"
                :elevation="0"
                @click.prevent
              >
                <span class="text-captions-2" :class="[dueDaysLeft.textColor]">{{ dueDaysLeft.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft.textColor]">
                  {{ dueDaysLeft.value }} {{dueDaysLeft.suffix }}
                </span>
              </v-btn>
            </template>
          </ModuleStyleDueDate>
        </div>
      </div>
      <div class="card-bottom__actions d-flex justify-space-between mt-2">
        <div>
          <template v-for="(member, idx) in project.team">
            <v-tooltip color="accent" top :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <UiAvatar
                  v-if="idx <= 1"
                  v-bind="attrs" v-on="on"
                  :key="idx" size="22"
                  :text="member.user.first_name"
                  :src="member.user.avatar && member.user.avatar.url || ''"
                  class="card-bottom__actions__avatar mr-2"
                />
              </template>
              <span>{{ member.user.first_name }} {{ member.user.last_name }}</span>
            </v-tooltip>
          </template>
          <UiAvatar
            v-if="project.total_users && project.total_users > 2"
            size="22"
            :text="`${project.total_users - 2}`"
            :src="null"
          />
        </div>
        <div>
          <ProjectMenu :project="project"/>
        </div>
      </div>

      <div v-if="project.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
            style="z-index: 2;" @click.prevent>
        <v-progress-circular
          :size="64"
          color="accent"
          indeterminate
        />
      </div>
    </template>
  </UiGridCard>
</template>

<script>
import {mapGetters} from "vuex";
import UiGridCard from "@/components/UI/UiGridCard"
import UIImage from "@/components/UI/UIImage";
import UiAvatar from "@/components/UI/UiAvatar";
import ProjectMenu from "@/views/dashboard/ProjectMenu";
import {format, differenceInDays, formatDistanceToNow} from "date-fns";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import {buildReviewRevisionLink, getFileThumbnails} from "@/utils/helpers";
import UiBadge from "@/components/UI/UiBadge.vue";
import IconChat from "@/components/icons/IconChat.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import ModuleStyleDueDate from "@/components/project/ModuleStyleDueDate.vue";

export default {
  name: 'ProjectGridCard',
  components: {
    UiBtn,
    IconChat,
    UiBadge,
    UiSelectedIndicator,
    UiGridCard,
    UIImage,
    UiAvatar,
    ProjectMenu,
    ModuleStyleDueDate,
  },
  props: {
    size: {
      type: String,
      default: 'large'
    },
    project: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapGetters([
      'getProjectsList',
    ]),
    isProjectCompleted() {
      return this.project.status === this.$config.project.status.approved
    }
  },
  methods: {
    buildReviewRevisionLink,
    format,
    getFileThumbnails,
    updateProject(data) {
      if(this.getProjectsList.find(el => el.is_folder && el.checked)) {
        return
      }
      this.$store.dispatch('updateDashboardProject', data);
    },
    timeDistance(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'dd/MM/yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    dueDaysLeft(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      const dueData = new Date(date).setHours(0, 0, 0, 0)
      return differenceInDays(dueData, now);
    },
    go(project) {
      if (project.active_module) {
        if (project.active_module.type === this.$config.project.moduleTypes.moodBoard) {
          return {name: 'MoodBoard', params: {project_id: project.id}};
        } else if (project.active_module.type === this.$config.project.moduleTypes.design) {
          return {
            name: 'ProjectDesign',
            params: {project_id: project.id},
            query: {module: project.active_module.id}
          };
        }
      } else {
        return  {name: 'ProjectEdit', params: {project_id: project.id}};
      }
    },
    goToComment(data) {
      this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.task;

      this.$router.replace({
        name: 'DesignViewPage',
        params: {project_id: data.project_id},
        query: {
          module_id: data.module_id,
          style_id: data.style_id,
          file_id: data.file_id,
          comment_id: data.comment_id,
          task_id: data?.task_id || undefined,
          view_mode: this.$config.project.designModes.task,
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card-image {
  background: var(--v-gray-30-base);

  img {
    object-fit: cover;
  }
}

.card-bottom {
  &__title {
    font-size: 14px;
    color: var(--v-gray-100-base);
  }

  &__content {
    font-size: 10px;
    color: var(--v-gray-60-base);

    &__right {
      color: var(--v-gray-80-base);

      .dark {
        color: var(--v-gray-100-base);
      }
    }
  }

  &__actions {
    &__avatar {
      border-color: var(--v-accent-base) !important;

      &:nth-child(2) {
        border-color: var(--v-accent-60-base) !important;
      }
    }
  }
}

.due-date-btn {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}
</style>
