<template>
  <div class="overflow-x-auto overflow-y-hidden">
    <div class="apex-wrapper" :style="{width: chartWidth}">
      <apexchart
        ref="chart"
        type="bar"
        :options="chartOptions"
        :series="series"
        height="370"
        class="my-apex"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {isEqual} from "@/utils/helpers";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    showModuleAverage: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const moduleAverageColor = "#68D4CD";
    const chartColours = [
      "#CFF67B",
      "#94DAFB",
      "#FD8080",
      "#6D848E",
      "#26A0FC",
      "#26E7A6",
      "#FEBC3B",
      "#FAB1B2",
      "#A572F9",
    ];
    return {
      chartColours,
      moduleAverageColor,
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              // zoom: true,
              // zoomin: true,
              // zoomout: true,
              pan: false,
              reset: false,
              customIcons: []
            },
          },
          type: "bar",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          crosshairs: {
            show: true,
            width: 'tickWidth'
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + " Hr.";
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
        },
        colors: [
            ...chartColours,
        ],
        fill: {
          opacity: 1,
        },
        grid: {
          show: true, // Показать сетку
          borderColor: "#DCDDDE40", // Цвет линий сетки
          position: "back", // Сетка на заднем плане
          xaxis: {
            lines: {
              show: true, // Показать вертикальные линии
            },
          },
          yaxis: {
            lines: {
              show: true, // Показать горизонтальные линии
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: 'left',
          fontSize: '10px',
          markers: {
            size: 4,
            shape: 'circle'
          },
          itemMargin: {
            horizontal: 8,
          },
          onItemClick: {
            toggleDataSeries: false
          },
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No data'
        }
      },
      oldChartData: null,
    };
  },
  computed: {
    chartBarColors() {
      const result = [];
      for (let i = 0; i < this.chartData?.series?.length; i++) {
        result.push(this.chartColours[i % this.chartColours.length]);
      }

      return result?.length ? result : this.chartColours;
    },
    chartWidth() {
      let baseCategoriesAmount = 20;
      if(this.chartData?.series?.[0]?.data?.length > 6) {
        baseCategoriesAmount = 10;
      }
      const oneCategoryWidth = 100 / baseCategoriesAmount;
      return this.chartData?.series?.length <= baseCategoriesAmount || !this.chartData?.series?.length ? '100%' : 100 + ((this.chartData?.series?.length - baseCategoriesAmount) * oneCategoryWidth) + '%';
    }
  },
  watch: {
    showModuleAverage: {
      handler(val) {
        this.toggleModuleAverage(val)
      },
    },
    chartData: {
      async handler(val) {
        if(isEqual(val, this.oldChartData)) {
          return
        }
        this.oldChartData = JSON.parse(JSON.stringify(val))
        const sectionsLabels = [...val.sectionsLabels]

        this.series = []
        this.series = [...val.series]
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: [...val.categories],
          },
          colors: [
            ...this.chartBarColors,
          ],
          tooltip: {
            shared: true,
            intersect: false,
            followCursor: true,
            custom: function ({ dataPointIndex }) {
              return `<div class="chart-tooltip-box">
                      <span>${sectionsLabels[dataPointIndex]}</span>
                    </div>`;
            },
          },
        }

        if(this.showModuleAverage) {
          this.toggleModuleAverage(true)
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    toggleModuleAverage(val) {
      if(val) {
        this.chartOptions = {
          ...this.chartOptions,
          colors: [
            this.moduleAverageColor,
            ...this.chartBarColors,
          ]
        }
        this.series = []
        this.series = [
          {
            name: "Module Average",
            data: this.chartData.categories.map((el, idx) => {
              return (this.chartData.series.reduce((acc, curr) => {
                return acc + curr.data[idx]
              }, 0) / this.chartData.series.filter(el => el.data[idx]).length) || 0
            }),
          },
          ...this.chartData.series
        ]
      } else {
        this.chartOptions = {
          ...this.chartOptions,
          colors: [
            ...this.chartBarColors,
          ]
        }
        this.series = []
        this.series = [...this.chartData.series]
      }
    },
  },
};
</script>

<style lang="scss">
.chart-tooltip-box {
  background: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  color: #000;
}

/*.highlight-section .apexcharts-bar-series:nth-child(1) {
  background-color: #e0f7fa !important;
}*/
.my-apex {
  .apexcharts-active {
    fill: rgba(220, 221, 222, 0.25) !important;
  }
  /*&.apexcharts-tooltip-active {
    .apexcharts-series {
      path {
        filter: none !important;
      }
    }
  }*/
  .apexcharts-series {
    path {
      filter: none !important;
    }
  }
  .apexcharts-xaxis-label {
    fill: #8C97A7 !important;
    font-size: 10px;
    font-family: "Inter", sans-serif !important;
  }
  .apexcharts-yaxis-label {
    fill: #B8C4CE !important;
    font-size: 10px;
  }
  .apexcharts-legend-text {
    font-family: "Inter", sans-serif !important;
    color: #8C97A7 !important;
  }
  .apexcharts-xaxis-tick {
    display: none;
  }
}
</style>
