export default axios => ({

  /**
   * Get Asset Status Tracking
   *
   * @link https://gosyqor.atlassian.net/wiki/spaces/UP1/pages/1695449098/Asset+Status+Tracking
   * @param params
   * @returns {*}
   */
  getAssetStatusTracking(params = {}) {
    return axios.get(`/analytics/asset-status-tracking`, {params})
  },

  /**
   * Get Projects completion ratio
   *
   * @link https://gosyqor.atlassian.net/wiki/spaces/UP1/pages/1695481906/Projects+completion+ratio
   * @param params
   * @returns {*}
   */
  getProjectsCompletionRatio(params = {}) {
    return axios.get(`/analytics/projects-completion-ratio`, {params})
  },

  /**
   * Get Project management metrics
   *
   * @link https://gosyqor.atlassian.net/wiki/spaces/UP1/pages/1696301058/Project+management+metrics
   * @param params
   * @returns {*}
   */
  getProjectManagementMetrics(params = {}) {
    return axios.get(`/analytics/project-management-metrics`, {params})
  },

  /**
   * Review and approval metrics
   *
   * @link https://gosyqor.atlassian.net/wiki/spaces/UP1/pages/1718845443/Review+and+approval+metrics
   * @param params
   * @returns {*}
   */
  getReviewAndApprovalMetrics(params = {}) {
    return axios.get(`/analytics/review-and-approval-metrics`, {params})
  },

})
