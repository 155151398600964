<template>
  <v-switch class="ui-switch"
              v-bind="$attrs" v-on="$listeners" :ripple="false">
    <template #default>
      <slot/>
    </template>
    <template #label>
      <slot name="label"/>
    </template>
    <template #message>
      <slot name="message"/>
    </template>
  </v-switch>
</template>

<script>
export default {
  name: "UiSwitch",
  inheritAttrs: false,
  model: {
    prop: 'inputValue',
    event: 'change',
  },
}
</script>

<style scoped lang="scss">
::v-deep.v-input.ui-switch {

  &.v-input--is-disabled {
    opacity: 0.7;
  }

  .v-input--switch__thumb {
    color: #FFFFFF !important;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));
  }

    .v-input--switch__track {
      color: var(--v-gray-30-base);
      opacity: 1;
    }


  .v-label {
    font-size: 12px;
  }
}
</style>
