<template>
  <div class="item py-3 px-4 d-flex justify-space-between align-center">
    <div class="d-flex align-center mr-auto overflow-hidden">
      <UiProjectModuleIcon
        width="32"
        :icon="item.icon"
        class="mr-3 flex-shrink-0 gray-60--text"
      />
      <span
        class="text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-medium text-body-md gray-100--text"
        :class="{'text-decoration-line-through': item.moduleStyle?.status === $config.project.status.approved}"
      >
        {{item.title }}
      </span>
    </div>
    <div>
      <div v-if="item.moduleStyle?.status === $config.project.status.approved" class="d-flex align-center text-body font-weight-medium success-100--text">
        <IconCheckCircle width="20" height="20" class="mr-2" />
        <span>Done</span>
      </div>

      <UiBtn
        v-else
        width="84"
        height="30"
        color="accent"
        class="text-body font-weight-medium"
        @click="$emit('updateModuleId', item.id)"
        :disabled="Boolean((item.moduleStyle && !item.moduleStyle?.ready_to_work) || (!item.moduleStyle && idx))"
      >
        Start
      </UiBtn>
    </div>
  </div>
</template>

<script>
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import {mapGetters} from "vuex";

export default {
  name: "UnavailableModuleInfoItem" ,
  components: {
    UiBtn,
    UiProjectModuleIcon,
    IconCheckCircle: () => import("@/components/icons/IconCheckCircle"),
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
    ]),
  }
}
</script>

<style scoped lang="scss">
.item {
  border-radius: 6px;
  border: 1px solid var(--v-gray-30-base);
  background: #FFF;
}
</style>
