<template>
  <div v-if="loading" class="d-flex justify-center pt-16">
    <v-progress-circular
      :size="100"
      color="accent"
      indeterminate
    />
  </div>
  <div v-else class="dashboard-page d-flex flex-column fill-height">
    <PageHeader :maxContainerWidth="maxContainerWidth" :border="true">
      <div class="d-flex justify-space-between align-center space-x-4">
        <h1 class="text-heading text-truncate">
          Analytics Dashboard
        </h1>
        <GlobalFilters
          :filter="queryParams"
          :filtersState="filtersState"
        />
      </div>
    </PageHeader>

    <v-container :style="{maxWidth: convertToUnit(maxContainerWidth)}">
      <v-row class="pt-6">
        <v-col
          cols="12"
          md="12"
        >
          <AssetStatusTrackingSection/>
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <v-col
          cols="12"
          md="12"
        >
          <MetricsSection />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import {convertToUnit} from "@/utils/helpers";
import GlobalFilters from "@/views/analytics/components/GlobalFilters.vue";
import AssetStatusTrackingSection from "@/views/analytics/components/AssetStatusTrackingSection.vue";
import MetricsSection from "@/views/analytics/components/MetricsSection.vue";

export default {
  name: "AnalyticsPage" ,
  components: {
    MetricsSection,
    AssetStatusTrackingSection,
    GlobalFilters,
    PageHeader,
  },
  data() {
    return {
      loading: true,
      maxContainerWidth: 1200,
      filtersState: {
        spaces: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        date: {
          loading: false,
          search: '',
          data: [
            // {title: 'Today', id: '0'},
            // {title: 'Yesterday', id: '1'},
            {title: 'Last 7 days', id: '7'},
            {title: 'Last 30 days', id: '30'},
            {title: 'Last 3 Months', id: '90'},
            {title: 'Last 6 Months', id: '180'},
            {title: 'Last Year', id: '360'},
            {title: 'Year to date', id: 'year_to_date'},
          ],
        },
      },
    }
  },
  async created() {
    this.loading = false;
    await this.setDefaultFilters()
  },
  computed: {
    queryParams() {
      return {
        spaces: Array.isArray(this.$route.query.spaces) ? this.$route.query.spaces.map(el => Number(el)) : this.$route.query.spaces ? [Number(this.$route.query.spaces)]  : [],
        date: this.$route.query.date || null, // TODO ask BE about date,
      }
    },
  },
  methods: {
    convertToUnit,
    async setDefaultFilters() {
      if(!this.queryParams.date) {
        await this.$router.push({query: {
          ...this.$route.query,
          date: '30'
        }})
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
