<template>
  <AnalyticsSectionWrapper>
    <div class="px-6 space-y-3">
      <h2 class="text-body font-weight-semi-bold gray-800--text">Asset Status Tracking</h2>
      <div>
        <ASTSFilters
          :filter="queryParams"
          :filtersState="filtersState"
          @updateFilter="updateFilter"
          @loadMoreFilters="loadFilters"
        />
      </div>
      <div
        v-if="this.queryParams[this.$config.analyticsConfig.filter_slugs.ast_date]"
        class="text-captions-2 gray-60--text"
      >
        Compared with the past {{ comparedText }} <span v-if="comparedDates">({{comparedDates }})</span>
      </div>
    </div>
    <div class="mt-3 mb-6 relative">
      <v-divider/>
      <v-progress-linear
          v-if="loadingData && assetStatusTrackingData"
          indeterminate
          color="accent"
          class="absolute"
      />
    </div>
    <div v-if="loadingData && !assetStatusTrackingData" class="loading d-flex justify-center align-center">
      <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
      />
    </div>
    <template v-else>
      <div class="mx-6">
        <AssetStatusTrackingChart
          :chartData="chartData"
          :showModuleAverage="moduleAverage === '1'"
          :assetStatusTrackingData="assetStatusTrackingData"
          :loading="loadingData"
        />
      </div>

      <v-row class="mx-6 mt-3 mb-6">
      <v-col class="rounded gray-10 px-5 py-3 mr-6 d-flex justify-center align-center">
        <div class="mr-2 font-weight-light text-body gray-800--text">Projects in space:</div>
        <div class="d-flex align-baseline">
          <div class="text-title-4 gray-100--text mr-1">{{projectsCompletionRatioData?.current?.total_projects}}</div>
          <div
              class="text-captions-2 font-weight-bold"
              :class="[projectCountChange > 0 ? 'success-100--text' : projectCountChange === 0 ? 'gray-100--text' : 'error-70--text']"
          >
            {{projectCountChange > 0 ? '↑ ' : projectCountChange === 0 ? ' ' : '↓ ' }}{{ projectsCompletionRatioData?.changes?.project_count_change }}
          </div>
        </div>
      </v-col>
      <v-col class="rounded gray-10 px-5 py-3 d-flex justify-center align-center">
        <div class="mr-2 font-weight-light text-body gray-800--text">Completion Ratio:</div>
        <div class="d-flex align-baseline">
          <div class="text-title-4 gray-100--text mr-1">{{projectsCompletionRatioData?.current?.percent_completion}}</div>
          <div
              class="text-captions-2 font-weight-bold"
              :class="[completionRatioChange > 0 ? 'success-100--text' : completionRatioChange === 0 ? 'gray-100--text' : 'error-70--text']"
          >
            {{completionRatioChange > 0 ? '↑ ' : completionRatioChange === 0 ? '-' : '↓ ' }}{{ projectsCompletionRatioData?.changes?.completion_ratio_change ? projectsCompletionRatioData.changes.completion_ratio_change : '' }}
          </div>
        </div>

      </v-col>
    </v-row>
    </template>

  </AnalyticsSectionWrapper>
</template>

<script>
import AnalyticsSectionWrapper from "@/views/analytics/components/AnalyticsSectionWrapper.vue";
import ASTSFilters from "@/views/analytics/components/ASTSFilters.vue";
import {highlightText, isEqual, formatTimeToHours} from "@/utils/helpers";
import {format} from "date-fns";
import AssetStatusTrackingChart from "@/views/analytics/components/AssetStatusTrackingChart.vue";

export default {
  name: "AssetStatusTrackingSection" ,
  components: {
    AssetStatusTrackingChart,
    ASTSFilters,
    AnalyticsSectionWrapper,
  },
  data() {
    return {
      loading: false,
      filtersState: {
        modules: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        ast_date: {
          loading: false,
          search: '',
          data: [
            // {title: 'Today', id: '0'},
            // {title: 'Yesterday', id: '1'},
            {title: 'Last 7 days', id: '7'},
            {title: 'Last 30 days', id: '30'},
            {title: 'Last 3 Months', id: '90'},
            {title: 'Last 6 Months', id: '180'},
            {title: 'Last Year', id: '360'},
            {title: 'Year to date', id: 'year_to_date'},
          ],
        },
      },
      FILTER_ITEMS_COUNT: 15,
      loadingData: true,
      projectsCompletionRatioData: null,
      assetStatusTrackingData: null,
      debounce: null,
      chartData: {
        categories: [],
        series: [],
        sectionsLabels: []
      },
      viewSectionsTypes: {
        'day': '1 day',
        'week': '7 days',
        'month': '1 month',
        'trimester': '3 months'
      }
    }
  },
  computed: {
    moduleAverage() {
      return this.$route.query.module_average || '0'
    },
    queryParams() {
      return {
        ast_date: this.$route.query.ast_date || null,
        modules: Array.isArray(this.$route.query.modules) ? this.$route.query.modules.map(el => el) : this.$route.query.modules ? [this.$route.query.modules]  : [],

        date: this.$route.query.date || null,
        spaces: Array.isArray(this.$route.query.spaces) ? this.$route.query.spaces.map(el => Number(el)) : this.$route.query.spaces ? [Number(this.$route.query.spaces)]  : [],
      }
    },
    comparedText() {
      switch (this.queryParams[this.$config.analyticsConfig.filter_slugs.ast_date]) {
        // case '0':
        //   return 'today'
        // case '1':
        //   return 'yesterday'
        case 'year_to_date':
          return ' 360 days'
        default:
          return ` ${this.queryParams[this.$config.analyticsConfig.filter_slugs.ast_date] || '7'} days`
      }
    },
    comparedDates() {
      if(!this.assetStatusTrackingData?.filters) {
        return null
      }
      return `${format(new Date(this.assetStatusTrackingData.filters.from).setHours(0,0,0,0), 'MMM dd, yyyy')} - ${format(new Date(this.assetStatusTrackingData.filters.to).setHours(0,0,0,0), 'MMM dd, yyyy')}`
    },
    projectCountChange() {
      return this.formatPercentageToNumber(this.projectsCompletionRatioData?.changes?.project_count_change)
    },
    completionRatioChange() {
      return this.formatPercentageToNumber(this.projectsCompletionRatioData?.changes?.completion_ratio_change)
    },
  },
  watch: {
    'queryParams.date': {
      async handler(val) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          if(val && val !== this.queryParams.ast_date) {
            await this.$router.push({query: {
                ...this.$route.query,
                ast_date: val
              }})
            await this.getInfo()
          }
        }, 600)
      }
    },
    'queryParams.spaces': {
      async handler(val, oldVal) {
        if(!isEqual(val, oldVal)) {
          clearTimeout(this.debounce)
          this.debounce = setTimeout(async () => {
            if(this.$route.query.modules?.length) {
              await this.$router.push({query: {
                  ...this.$route.query,
                  modules: [] // TODO ask Laurin
                }})
            }
            await this.getInfo()
          }, 600)
        }
      }
    },
    assetStatusTrackingData: {
      handler(val) {
        if(val) {
          this.chartData = this.setChartData()
        }
      }
    }
  },
  async mounted() {
    await this.getModules()
    await this.setDefaultFilters()
    await this.getInfo(false)
  },
  methods: {
    formatTimeToHours,
    updateFilter() {
      this.getInfo(false)
    },
    loadFilters(data) {
      if(data.type === this.$config.analyticsConfig.filter_slugs.modules) {
        this.getModules(true)
      }
    },
    async getInfo() {
      console.log('getInfo');
      try {
        this.loadingData = true

        await Promise.all([
          this.fetchProjectsCompletionRatio(),
          this.fetchAssetStatusTracking(),
        ])

      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false
      }
    },
    async getModules(merge, titles) {
      this.filtersState[this.$config.analyticsConfig.filter_slugs.modules].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.analyticsConfig.filter_slugs.modules].page,
        }
        if(titles) {
          params.titles = titles
        }
        if(this.filtersState[this.$config.analyticsConfig.filter_slugs.modules]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.analyticsConfig.filter_slugs.modules]?.search
          params.page = 1
        }
        const res = await this.$api.dashboard.getFilterModules(params)

        const mapedData = [...new Set(res.data.map(module => module.title))].map(title => ({ // filter duplicates
          title: title,
          id: title
        })).filter(module => !this.filtersState[this.$config.analyticsConfig.filter_slugs.modules].data.map(el => el.id).includes(module.id) )

        this.filterFetchDataSetter({merge, data: mapedData, type: this.$config.analyticsConfig.filter_slugs.modules, meta: res.meta, ids: titles})

      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.analyticsConfig.filter_slugs.modules].loading = false
      }
    },
    filterFetchDataSetter({merge, data, type, meta, ids}) {
      if(merge) {
        const itemsToMerge = data.filter(el => !this.filtersState[type].data.find(item => item.id === el.id))
        this.filtersState[type].data = [...this.filtersState[type].data, ...itemsToMerge];
      } else {
        this.filtersState[type].data = data
      }

      if(!ids && !this.filtersState[type].search) {
        this.filtersState[type].meta = meta
      }

      if(this.filtersState[type].search) {
        this.filtersState[type].data.forEach(item => {
          item.htmlTitle = highlightText(this.filtersState[type].search || '', item.title || '');
        });
      }
    },
    async setDefaultFilters() {
      const defaultQuery = {}
      if(!this.queryParams.ast_date) {
        defaultQuery.ast_date = this.queryParams.date || '30'
      }

      if(Object.keys(defaultQuery).length) {
        await this.$router.push({
          query: {
            ...this.$route.query,
            ...defaultQuery
          }
        })
      }
    },
    async fetchProjectsCompletionRatio() {
      try {
        const params = {
          spaces: this.queryParams.spaces,
          modules: this.queryParams.modules,
          date: this.queryParams.ast_date,
        }
        const {data} = await this.$api.analytics.getProjectsCompletionRatio(params)
        this.projectsCompletionRatioData = data
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async fetchAssetStatusTracking() {
      try {
        const params = {
          spaces: this.queryParams.spaces,
          modules: this.queryParams.modules,
          date: this.queryParams.ast_date,
        }
        this.assetStatusTrackingData = await this.$api.analytics.getAssetStatusTracking(params)
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    formatPercentageToNumber(data) {
      if(!data) {
        return 0
      }
      return Number(data.replace('%', ''))
    },
    setChartData() {
      if(!this.assetStatusTrackingData?.data) {
        return {}
      }

      const categories = this.assetStatusTrackingData?.data?.map((el, idx) => {
        if(this.assetStatusTrackingData.filters.interval === this.viewSectionsTypes.day) {
          const date = new Date(el.period_start_date).setHours(0, 0, 0, 0)
          return format(date, 'EEEE')
        } else if(this.assetStatusTrackingData.filters.interval === this.viewSectionsTypes.week) {
          return `Week ${idx + 1}`
        } else if(this.assetStatusTrackingData.filters.interval === this.viewSectionsTypes.month || this.assetStatusTrackingData.filters.interval === this.viewSectionsTypes.trimester) {
          const formatTemplate = this.assetStatusTrackingData.filters.date === "90" ? "LLLL" : "MMM"
          const startMonth = format(new Date(el.period_start_date).setHours(0, 0, 0, 0), formatTemplate)
          const endMonth = format(new Date(el.period_end_date).setHours(0, 0, 0, 0), formatTemplate)
          return startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`
        }
        return idx + 1
      })

      let series = this.queryParams.modules.map(moduleTitle => ({
        name: moduleTitle,
        data: new Array(categories.length).fill(0),
      }))
      if(!series.length) {
        series = this.assetStatusTrackingData?.data.reduce((acc, period) => {
          period.modules.forEach(module => {
            if (!acc.some((el) => el.name === module.module_title)) {
              acc.push({
                name: module.module_title,
                data: new Array(categories.length).fill(0),
              });
            }
          });
          return acc;
        }, []);
      }
      this.assetStatusTrackingData?.data.forEach((period, periodIdx) => {
        period.modules.forEach(module => {
          const seriesModuleIdx = series.findIndex(item => item.name === module.module_title)
          if(series[seriesModuleIdx]?.data) {
            series[seriesModuleIdx].data[periodIdx] = this.formatTimeToHours(module.avg_completion_time)
          }
        })
      })
      return {
        categories: categories,
        series: series,
        sectionsLabels: this.assetStatusTrackingData?.data.map((el) => {
          if(el.period_start_date === el.period_end_date) {
            return el.period_start_date
          }
          return `${el.period_start_date} <strong>to</strong> ${el.period_end_date}`
        }) || []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  min-height: 474px;
}
</style>
