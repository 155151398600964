<template>
  <div class="un-container relative pt-13 px-4 px-sm-7 pb-6 fill-height d-flex flex-column align-center justify-center">
    <h2 class="mb-6 text-title-2 gray-100--text text-center">
      You cannot perform this<br> step until
    </h2>
    <div class="content-wrapper py-4 px-6">
      <p class="text-start text-body-lg gray-100--text font-weight-medium mb-12">You need to approve everything in <span class="font-weight-bold">{{ lastReadyToWorkModuleStyle?.title }}</span> before proceeding to <span class="font-weight-regular">this step</span></p>
      <UnavailableModuleInfoItem
        v-for="(item, idx) in unavailableModulesList"
        :key="item.id"
        class="mb-2"
        :item="item"
        :idx="idx"
        @updateModuleId="$emit('updateModuleId', $event)"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UnavailableModuleInfoItem from "@/views/project/design-view/UnavailableModuleInfoItem.vue";

export default {
  name: 'UnavailableModuleInfo',
  components: {
    UnavailableModuleInfoItem
  },
  props: {
    styleListView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataLoading: true,
      submitLoading: false,
      open: true,
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getActiveStyle',
      'getActiveDesignModule',
    ]),
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
    },
    currentStyleModules() {
      if(!this.getProject?.modules?.length) {
        return []
      }
      const currentStyleModules = this.getProject?.modules?.reduce((acc, module) => {
        if(module.workflow_type === this.getActiveDesignModule.workflow_type) {
          acc.push({
            ...module,
            moduleStyle: module.styles.find(style => style?.sort_order === this.getActiveStyle?.sort_order)
          })
        }
        return acc
      }, []);
      currentStyleModules.sort((a, b) => a.moduleStyle?.sort_order - b.moduleStyle?.sort_order);
      return currentStyleModules
    },
    lastReadyToWorkModuleStyle() {
      return this.currentStyleModules.findLast(module => module.moduleStyle?.ready_to_work) || this.currentStyleModules?.[0];
    },
    unavailableModulesList() {
      if(!this.getProject || this.getProject?.scheme?.settings[this.activeModuleWorkflowOpenTypeSlug]) {
        return []
      }
      if(this.currentStyleModules.length <= 5) {
        return [...this.currentStyleModules]
      }

      let payload = [];

      const currentModuleIndex = this.currentStyleModules.findIndex(module => this.getActiveDesignModule.id === module.id);
      const lastCompletedStyle = this.currentStyleModules.findLast(module => module.moduleStyle?.status === this.$config.project.status.approved);

      const minI = currentModuleIndex > 2 ? -2 : -currentModuleIndex;
      const maxI = this.currentStyleModules.length - currentModuleIndex + 1 > 2 ? 2 : this.currentStyleModules.length - currentModuleIndex + 1;

      for(let i = minI; i <= maxI; i+= 1) {
        const module = this.currentStyleModules[currentModuleIndex + i];
        if(module && !payload.some(item => item.id === module.id)) {
          payload.push(module)
        }
      }

      if(this.lastReadyToWorkModuleStyle && !payload.some(module => module.id === this.lastReadyToWorkModuleStyle?.id)) { // set lastReadyToWorkStyle if no exist
        if(payload.length >= 5) {
          payload.splice(0, 1, this.lastReadyToWorkModuleStyle);
        } else {
          payload.unshift(this.lastReadyToWorkModuleStyle)
        }
      }

      if(lastCompletedStyle && payload.length < 5 && lastCompletedStyle && !payload.some(module => module.id === lastCompletedStyle?.id)) { // set lastCompletedStyle if no exist
        payload.unshift(lastCompletedStyle)
      }
      return payload
    }
  },
  async created() {

  },
  mounted() {

  },
  methods: {
    hideModal() {
      this.modalActions.onClose();
      this.$store.dispatch('closeModal', 'createNewTaskModal');
    }
  }
}
</script>

<style scoped lang="scss">
.content-wrapper {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 11px 20px 9px rgba(17, 12, 36, 0.10);
  width: 550px;
}
.un-container {
  letter-spacing: normal;
}
</style>
