export default {
  methods: {
    transformData(data) {
      return data.map(item => {
        const items = this.generateItems(item.styles || []);
        return {
          ...item,
          item: items[0], // first sorted item
          items
        };
      });
    },
    generateItems(styles) {
      return styles.reduce((items, style) => {
        if (!style.project_items.length) {
          // Add style directly if project_items is empty
          items.push({ style });
        } else {
          // Add each project_item with style info
          items.push(
            ...style.project_items.map(item => ({
              ...item,
              style
            }))
          );
        }
        return items;
      }, [])
        // Sort items by created_at (earliest to latest)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
  }
};
