<template>
  <DatePicker
    v-on="$listeners"
    v-bind="$attrs"
    :disabled-date="disabledDate"
    :disabled="disabled"
    :value="value"
    :confirm="!hideFooter"
    :clearable="false"
    :open.sync="isOpen"
    :lang="{
      formatLocale: {
        // MMMM
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        // MMM
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        // dddd
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        // ddd
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        // dd
        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        // first day of week
        firstDayOfWeek: 0,
        // first week contains January 1st.
        firstWeekContainsDate: 1
      }
    }"
    @open="toggleCalendar"
    @close="toggleCalendar"
    @pick="handleChange"
    title-format="YYYY-MM-DD"
    value-type="timestamp"
    :popup-class="['ui-due-datepicker', popupClass]"
    :inline="inline"
    class="ui-due-date"
    :class="{'ui-due-datepicker ui-due-datepicker-inner': inline}"
  >
    <template v-slot:header>
      <slot v-if="!hideHeader" name="header" :date="newDueDate">
        <div class="ui-due-datepicker-header">
          <IconClockSolid width="24"/>
          Due date
          <span class="font-weight-bold">{{ format(newDueDate, 'MMM dd, yyyy') }}</span>
        </div>
      </slot>
    </template>
    <template v-slot:input>
      <div :class="{
        'is-open--input' : isOpen,
        'is-closed--input': !isOpen,
        'is-disabled--input': disabled,
      }">
        <slot name="input" :open="isOpen" :dayLeft="daysLeft" :value="value">
          <UiBtn
            outlined
            width="165"
            :color="daysLeft < 0 ? 'error' : 'success-100'"
            class="due-date-btn text-body text-capitalize font-weight-light"
          >
            <IconClockSolid width="20" class="mr-2"/>
            <template v-if="daysLeft < 0">Missed due</template>
            <template v-else>{{ daysLeft }} {{ daysLeft === 1 ? 'day' : 'days' }} left</template>
            <IconPen width="18" class="ml-4"/>
          </UiBtn>
        </slot>
      </div>
    </template>
    <template v-slot:footer>
      <slot v-if="!hideFooter" name="footer" :cancel="cancel" :save="save">
        <div class="mb-3">
          <UiBtn
            color="accent"
            outlined
            type="button"
            @click="cancel"
            class="flex-shrink-0 cancel-btn"
            width="78"
          >
            Cancel
          </UiBtn>
          <UiBtn
            color="accent"
            type="button"
            @click="save"
            class="flex-shrink-0 save-btn"
            width="98"
          >
            Save
          </UiBtn>
        </div>
      </slot>
      <slot name="bottom"/>
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import {differenceInDays, format} from 'date-fns'
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "UIDatePicker",
  components: {
    IconClockSolid: () => import('@/components/icons/IconClockSolid'),
    IconPen: () => import('@/components/icons/IconPen'),
    DatePicker,
    UiBtn,
  },
  props: {
    value: {
      type: [String, Number],
      default: new Date(),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    title: {
      type: String,
      default: 'Due date'
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    popupClass: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      newDueDate: new Date().getTime(),
    }
  },
  computed: {
    daysLeft() {
      const now = new Date();

      return differenceInDays(new Date(this.value), now)
    }
  },
  watch: {
    value: {
      handler(value) {
        if(!value) return;
        this.newDueDate = new Date(value).getTime();
      },
      immediate: true
    }
  },
  methods: {
    format,
    toggleCalendar() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen)
    },
    handleChange(value) {
      this.newDueDate = new Date(value).getTime();
    },
    save() {
      this.toggleCalendar();
      this.$emit('input', this.newDueDate)
      this.$nextTick(() => {
        this.$emit('save', {
          value: this.newDueDate,
          daysLeft: this.daysLeft
        })
      })
    },
    cancel() {
      this.toggleCalendar();
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss">
.mx-datepicker.ui-due-date {
  width: auto;

  .mx-icon-calendar {
    display: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}



//.mx-datepicker-main.ui-due-datepicker {
.mx-datepicker-main.ui-due-datepicker, .ui-due-datepicker > .mx-datepicker-main {


  width: 320px;
  margin-top: 6px;
  border: 1px solid var(--v-gray-30-base);
  border-radius: 4px;
  box-shadow: 0 4px 31px rgb(0, 0, 0, 0.1);
  color: #8181A5;

  .mx-datepicker-header {
    padding: 8px 14px;
    border: none;
  }

  .ui-due-datepicker-header {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 2px;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    letter-spacing: 0.12px;

    border: 1px solid var(--v-warning-base);
    color: var(--v-primary-60-base);

    svg {
      margin: -3px 12px 0 0;
      font-size: 24px;
    }

    span {
      margin-left: auto;
    }
    .font-weight-bold {
      font-weight: 700;
    }
  }

  .mx-calendar-panel-date .mx-btn-icon-double-left,
  .mx-calendar-panel-date .mx-btn-icon-double-right {
    display: none;
  }

  .mx-btn-icon-left,
  .mx-btn-icon-right,
  .mx-btn-icon-double-left,
  .mx-btn-icon-double-right {
    i::before {
      width: 14px;
      height: 14px;
      border-width: 3px 0 0 3px;
    }

    i::after {
      display: none;
    }
  }

  .mx-btn-current-month:hover,
  .mx-btn-current-year:hover,
  .mx-btn-icon-left:hover,
  .mx-btn-icon-right:hover {
    border-color: #6FCF97;
    color: #6FCF97;
  }

  .mx-calendar {
    padding: 0;
    width: 100%;
  }

  .mx-calendar-header,
  .mx-time-header {
    height: 36px;
    padding: 0 20px;
    line-height: 36px;
    //background-color: #FCFCFC;
  }
  .mx-calendar-header {
    padding: 0 50px;
    .mx-btn {
      color: var(--v-primary-base);
      .mx-calendar-header-label {
        font-size: 17px;
      }
    }
  }

  .mx-table th {
    width: 28px;
    height: 28px;
    font-weight: 600;
    font-size: 12px;
    color: #16161A;
    font-family: "Inter", sans-serif;
  }

  .mx-table td {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin: auto;
      border: 2px solid transparent;
      border-radius: 50%;
    }
  }

  .mx-calendar.mx-calendar-panel-date {
    .mx-calendar-content {
      padding: 12px 20px;
      height: auto;

      .cell {
        color: #16161A;
        font-family: "Inter", sans-serif;
        &.disabled {
          color: #ccc;
          background-color: transparent;
        }
        &.not-current-month {
          height: 0;
          overflow: hidden;
          opacity: 0;
          pointer-events: none;
          div {
            display: none;
          }
        }
      }

      .cell:not(.disabled) {
        &:hover,
        &.active {
          background-color: #fff;
          color: #fff;

          div {
            background-color: var(--v-success-100-base);
            color: #fff;
          }
        }

        &.today {
          font-weight: 600;
          color: #16161A !important;

          div {
            color: #16161A !important;
          }
        }
      }
    }
  }

  .mx-calendar-panel-month .cell,
  .mx-calendar-panel-year .cell {
    &:hover {
      background-color: #FF6363;
      color: #fff;
    }

    &.active {
      background-color: #6FCF97;
      color: #fff;
    }
  }

  .mx-datepicker-footer {
    padding: 0 20px 22px;
    border: none;

    button {
    //  margin-left: 8px;
    //  width: 120px;
    //  height: 36px;
    //  text-transform: uppercase;
    //  font-weight: 500;
    //  border: 1px solid transparent;
    //  border-radius: 4px;
    //
    //  &.due-datepicker-save-btn {
    //    border-color: rgba(129, 129, 165, 0.4);
    //  }
    //
    //  &:hover {
    //    background-color: rgba(0, 0, 0, .1);
    //    border-color: rgba(129, 129, 165, 0.4);
    //  }
      border-color: var(--v-primary-base);
      &.cancel-btn {
        color: var(--v-primary-base);
      }
      &.save-btn {
        margin-left: 4px;
        background: var(--v-primary-base);
      }
    }

    .mx-datepicker-btn-confirm {
      display: none;
    }
  }

  @media screen and (max-width: 344px) {
    width: 296px;
  }

}

.ui-due-datepicker-inner {
  .mx-datepicker-main {
    width: 100%;
    border-radius: 0;
    border: none;
    margin-top: 0;
  }

}
</style>
