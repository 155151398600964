<template>
  <div
    class="mx-auto border py-4"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: "AnatyticsSectionWrapper"
}
</script>

<style scoped lang="scss">
.border {
  border: 1px solid var(--v-gray-25-base);
  border-radius: 8px;
}
</style>
