<template>
  <div>
    <UiMenu
      bottom
      left
      offset-x
      nudge-left="4"
      min-width="160"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip right color="rgba(47, 49, 53, 0.7)">
          <template #activator="{attrs:attrsTooltip, on:onTooltip}">
            <ui-btn
              v-bind="{...attrs, ...attrsTooltip}"
              v-on="{...on, ...onTooltip}"
              icon
            >
              <UiProjectStatus :id="getActiveFile.status" circle/>
            </ui-btn>
          </template>
          <div class="text-captions-1">
            {{ $config.project.statusLabels[getActiveFile.status] }}
          </div>
        </v-tooltip>
      </template>

      <v-list nav dense color="gray-10">
        <v-list-item
          :disabled="!checkIfDisabled($config.project.status.in_review) || !abilityToRequestReview"
          @click="requestReview">
          <v-list-item-title class="text-capitalize">
            <UiProjectStatus :id="$config.project.status.in_review"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!checkIfDisabled($config.project.status.rejected) || !abilityToReview"
          @click="approveOrReject($config.project.status.rejected)"
        >
          <v-list-item-title class="text-capitalize">
            <UiProjectStatus :id="$config.project.status.rejected"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!checkIfDisabled($config.project.status.approved) || !abilityToReview"
          @click="approveOrReject($config.project.status.approved)"
        >
          <v-list-item-title class="text-capitalize">
            <UiProjectStatus :id="$config.project.status.approved"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!checkIfDisabled($config.project.status.archived) || !getPermissionByModule('can-archive-design')"
          @click="updateStatus('archive')"
        >
          <v-list-item-title class="text-capitalize">
            <UiProjectStatus :id="$config.project.status.archived"/>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </UiMenu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiMenu from "@/components/UI/UiMenu";

export default {
  name: 'ActiveFileStatusIndicator',
  components: {UiMenu, UiProjectStatus, UiBtn},
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getActiveStyle',
      'getActiveFile',
      'getProject',
      'getPermission',
      'getProjectPermissionData',
    ]),
    abilityToRequestReview() {
      return this.getActiveFile.status !== this.$config.project.status.approved && this.getPermissionByModule('can-request-review-design') || this.isManagerOrOwner;
    },
    abilityToReview() {
      return this.getActiveFile.request_reviews.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner;
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(module => module.id === +this.$route.query.module_id);
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    checkIfDisabled(status) {
      return this.getActiveFile.status !== status;
    },
    requestReview() {
      if (!this.abilityToRequestReview) return;

      const file = {
        ...this.getActiveStyle,
        items: this.getActiveStyle.items.filter(item => item.id === this.getActiveFile.id)
      };
      this.$store.dispatch('openModal', {
        modalName: 'requestReviewModal',
        data: {
          files: [file],
          module_id: this.$route.query.module_id,
        },
      });
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: [this.getActiveFile],
        },
      });
    },
    updateStatus(status) {
      if (status === 'archive' && !this.getPermissionByModule('can-archive-design' || this.$store.state.ProjectDesign.activeFile.loading)) {
        return;
      }

      this.$store.state.ProjectDesign.activeFile.loading = true;

      const items = this.getActiveStyle.items.map(file => file.id === this.getActiveFile.id ? {
        ...file,
        loading: true
      } : file);
      this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

      this.$api.projectModuleDesign.updateStatus(this.$route.params.project_id, this.$route.query.module_id, this.getActiveFile.id, status)
        .then(res => {
          const items = this.getActiveStyle.items.map(file => file.id === res.data.id ? {
            ...res.data,
            loading: false
          } : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
          this.$store.dispatch('setActiveFile', {...res.data, loading: false});
        })
        .catch(err => {
          this.$store.state.ProjectDesign.activeFile.loading = false;
          const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
          console.error(err);
        })
    },
  },
}
</script>
