<template>
  <v-dialog
    v-model="getModal('createWorkflowModal').isOpen"
    max-width="520"
  >
    <div class="pa-9 gray-0">

      <div class="d-flex align-center">
        <h5 class="text-title-2 gray-100--text">Edit Workflow</h5>
      </div>
      <v-divider class="mt-4 mb-5"/>

      <ValidationObserver ref="form" v-slot="{ handleSubmit }">

        <v-form @submit.prevent="handleSubmit(saveAndClose)">
          <v-flex v-if="loading" class="d-flex justify-center align-center">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate/>
          </v-flex>
          <ValidationProvider v-else class="mb-8" mode="eager" tag="div"
            name="workflow name" rules="required|min:3|max:100" v-slot="{ errors }"
          >
            <div class="mb-2 text-body font-weight-bold gray-100--text">Workflow Name</div>
            <v-text-field
              v-model="workflowName"
              dense
              outlined
              hide-details
              placeholder="Workflow Name"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>
          <div class="d-flex">
            <UiBtn
              outlined
              width="104"
              color="accent"
              @click="$store.dispatch('closeModal', 'createWorkflowModal')"
              class="ml-auto mr-3"
            >
              Cancel
            </UiBtn>
            <UiBtn
              width="126"
              color="accent"
              type="submit"
              :disabled="loading"
            >
              Create
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>


    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'createWorkflowModal',
  components: {
    UiBtn
  },
  data() {
    return {
      loading: false,
      workflowName: '',
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getCategories',
      'getModal',
      'getActiveSpace'
    ]),
  },
  methods: {
    async saveAndClose() {
      this.loading = true;
      const schemeId = await this.createNewScheme();
      this.$store.dispatch('closeModal', 'createWorkflowModal');
      this.$emit('onCreate', schemeId)
      this.loading = false;
    },
    async createNewScheme() {
      try {
        const defaultSchema = await this.$api.workflowScheme.get(1)
        const newScheme = await this.$api.workflowScheme.create({ ...this.getFormattedScheme(defaultSchema),});
        this.selectedWorkflow = newScheme.data.id;
        return newScheme.data.id;
      } catch (error) {
        console.error(error);
      }
    },
    getFormattedScheme(scheme) {
      return {
        title: this.workflowName,
        category: scheme.categories[0].id,
        space_id: this.getActiveSpace?.id,
        main: {
          open_workflow: scheme.settings.open_workflow_main,
          include_weekend: scheme.settings.include_weekend_main,
          scheme_design: scheme.main.map((module, idx) => ({
            ttl: this.formatTtl(module.ttl),
            module_id: module.module_id,
            original_module_id: module.original_module_id || module.module_id,
            title: module.title,
            icon: module.icon,
            type: module.type,
            approve: false,
            sort_order: idx + 1,
            from: 'from' in module ? module.from : [],
            to: 'to' in module ? module.to : [],
            parent_id: 'parent_id' in module ? module.parent_id : 0,
          }))
        },
        additional: {
          open_workflow: scheme.settings.open_workflow_additional,
          include_weekend: scheme.settings.include_weekend_additional,
          scheme_design: scheme.additional.map((module, idx) => ({
            ttl: this.formatTtl(module.ttl),
            module_id: module.module_id,
            original_module_id: module.original_module_id || module.module_id,
            title: module.title,
            icon: module.icon,
            type: module.type,
            approve: false,
            sort_order: idx + 1,
            from: 'from' in module ? module.from : [],
            to: 'to' in module ? module.to : [],
            parent_id: 'parent_id' in module ? module.parent_id : 0,
          }))
        }
      };
    },
    formatTtl(ttl) {
      return parseInt(ttl.toString().replace(' Days', ''), 10)
    },
  }
}
</script>
