<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 1.75C7.17924 1.75 6.36651 1.91166 5.60823 2.22575C4.84994 2.53984 4.16095 3.00022 3.58058 3.58058C2.40848 4.75269 1.75 6.3424 1.75 8C1.75 9.6576 2.40848 11.2473 3.58058 12.4194C4.16095 12.9998 4.84994 13.4602 5.60823 13.7742C6.36651 14.0883 7.17924 14.25 8 14.25C9.6576 14.25 11.2473 13.5915 12.4194 12.4194C13.5915 11.2473 14.25 9.6576 14.25 8C14.25 7.17924 14.0883 6.36651 13.7742 5.60823C13.4602 4.84994 12.9998 4.16095 12.4194 3.58058C11.8391 3.00022 11.1501 2.53984 10.3918 2.22575C9.63349 1.91166 8.82076 1.75 8 1.75ZM10.625 10.625L7.375 8.625V4.875H8.3125V8.125L11.125 9.8125L10.625 10.625Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconClockFilled"
}
</script>
