<template>
  <UiMenu
    v-model="menu"
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" fab color="accent" :disabled="designsLoading">
        <IconPlus width="16"/>
      </UiBtn>
    </template>

    <v-list nav dense color="gray-10">
      <v-list-item :disabled="!abilityToUpload">
        <v-list-item-title class="text-captions-1">
          <UIFilesUploader
            :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
            :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
            :disabled="!abilityToUpload"
            multiple
            @onChange="createDesign"
            class="d-flex align-center overflow-hidden cursor-pointer"
            style="padding: 7px 32px 7px 0;"
          >
            <IconUpload width="15" class="mr-2 gray-60--text"/>
            New Style
          </UIFilesUploader>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import UIFilesUploader from "@/components/UI/UIFilesUploader";

export default {
  name: 'ModulesMenu',
  components: {
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconUpload: () => import('@/components/icons/IconUpload'),

    UiMenu,
    UiBtn,
    UIFilesUploader,
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'designsLoading',
      'getProjectDesignsFilters',
      'getActiveDesignModule',
      'getProjectPermissionData',
      'getPermission',
    ]),
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
    },
    abilityToUpload() {
      const modules = this.getProject.modules.filter(module => module.workflow_type === this.getActiveDesignModule.workflow_type);
      modules.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)
      const currentModuleIdx = modules.findIndex(module => module.id === this.getActiveDesignModule.id);
      if(!this.getProject?.scheme?.settings[this.activeModuleWorkflowOpenTypeSlug] && currentModuleIdx) {
        return false
      }

      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design['can-create-style-design'] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    }
  },
  methods: {
    createDesign(files) {
      this.menu = false;
      this.$emit('createDesign', files);
    },
  },
}
</script>
