<template>
  <UiMenu
    :top="bottomActions"
    :bottom="!bottomActions"
    :left="$vuetify.breakpoint.xsOnly && bottomActions "
    offset-y
    :nudge-top="bottomActions ? 8: -8"
    :min-width="$vuetify.breakpoint.xsOnly && bottomActions ? 'calc(100% - 24px)' : 155"
    z-index="10"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn
        :icon="!bottomActions"
        v-bind="attrs"
        v-on="on"
        :color="bottomActions ? 'accent' : 'gray-60'"
      >
        <IconDotsH v-if="!bottomActions" width="14"/>
        <template v-else>
          <IconArrowUp width="14" class="mr-2"/>
          More Actions
        </template>
      </UiBtn>
    </template>

    <v-list nav dense color="gray-10">
      <v-list-item
        v-if="!hidePartMenu"
        :disabled="!getPermission($route.params.project_id).design['can-share-design'] || true"
      >
        <v-list-item-title class="text-captions-1">
          <IconShare width="14" class="mr-2 gray-60--text"/>
          Share design
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="fileLoading || !this.getActiveFile"
        @click="downloadFile"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download design
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!hidePartMenu"
        :disabled="!abilityToRequestReview"
        @click="requestReview"
      >
        <v-list-item-title class="text-captions-1">
          <IconSendMessage width="14" class="mr-2 gray-60--text"/>
          Request review
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!hidePartMenu"
        :disabled="checkIfDisabled($config.project.status.rejected) || !abilityToReview"
        @click="approveOrReject($config.project.status.rejected)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCancel width="14" class="mr-2 gray-60--text"/>
          Request adjustments
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!hidePartMenu"
        :disabled="checkIfDisabled($config.project.status.approved) || !abilityToReview"
        @click="approveOrReject($config.project.status.approved)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCheck width="14" class="mr-2 gray-60--text"/>
          Approve design
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!hidePartMenu && (bottomActions && $vuetify.breakpoint.xsOnly || !bottomActions)"
        :disabled="checkIfDisabled($config.project.status.archived) || !getPermissionByModule('can-archive-design')"
        @click="updateStatus('archive')"
      >
        <v-list-item-title class="text-captions-1">
          <IconArchive width="14" class="mr-2 gray-60--text"/>
          Archive design
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!hidePartMenu && (bottomActions && $vuetify.breakpoint.xsOnly || !bottomActions)"
        :disabled="!this.getActiveFile || !canDelete"
        @click="deleteItems"
      >
        <v-list-item-title class="text-captions-1">
          <IconDelete width="14" class="mr-2 gray-60--text"/>
          Delete design
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";

export default {
  name: 'FileActionsMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconArrowUp: () => import('@/components/icons/IconArrowUp'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconSendMessage: () => import('@/components/icons/IconSendMessage'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconArchive: () => import('@/components/icons/IconArchive'),

    UiMenu,
    UiBtn,
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    bottomActions: {
      type: Boolean,
      default: false,
    },
    hidePartMenu: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getActiveStyle',
      'getActiveFile',
      'getProject',
      'getPermission',
      'getProjectPermissionData',
      'getUserActiveProjectRoles',
    ]),
    abilityToRequestReview() {
      if (!this.getActiveFile) return false;
      return this.getActiveFile.status !== this.$config.project.status.approved && this.getPermissionByModule('can-request-review-design') || this.isManagerOrOwner;
    },
    abilityToReview() {
      if (!this.getActiveFile) return false;
      const activeFile = this.file || this.getActiveFile;
      return activeFile.request_reviews.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner;
    },
    canDelete() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.getActiveFile.created_by === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
      ) && this.getPermissionByModule('can-delete-file-design');
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(module => module.id === +this.$route.query.module_id);
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    checkIfDisabled(status) {
      if (!this.getActiveFile) return true;
      const activeFile = this.file || this.getActiveFile;
      return activeFile.status === status;
    },
    requestReview() {
      if (!this.abilityToRequestReview) return;

      const activeFile = this.file || this.getActiveFile;
      const file = {...this.getActiveStyle, items: this.getActiveStyle.items.filter(item => item.id === activeFile.id)};

      this.$store.dispatch('openModal', {
        modalName: 'requestReviewModal',
        data: {
          files: [file],
          module_id: this.$route.query.module_id,
        },
      });
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: [this.getActiveFile],
        },
      });
    },
    deleteItems() {
      if (!this.canDelete) return;

      const activeFile = this.file || this.getActiveFile;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete this file?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            const items = this.getActiveStyle.items.map(file => file.id === activeFile.id ? {
              ...file,
              loading: true
            } : file);
            this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
            this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteFiles(this.$route.params.project_id, this.$route.query.module_id, {items: [activeFile.id]});

              const items = this.getActiveStyle.items.filter(file => file.id !== activeFile.id);
              const groupIdx = +Object.keys(this.getActiveStyle.grouped_items).indexOf(String(activeFile.gallery_group_id))

              this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

              if((this.getActiveStyle.grouped_items && Object.keys(this.getActiveStyle.grouped_items).length) && !this.getActiveStyle.grouped_items[this.$route.query.gallery_group_id]) {
                const currentGroupId = Object.keys(this.getActiveStyle.grouped_items)[groupIdx <= 0 ? 0 : groupIdx - 1]
                const currentFileId = this.getActiveStyle.grouped_items[currentGroupId][0].id
                const currentFile = items.find(file => +file.id === +currentFileId)
                if (activeFile.id === this.getActiveFile.id) {
                  this.$store.dispatch('setActiveFile', {...currentFile, loading: true});
                }
                this.$router.$updateQueryParams({gallery_group_id: currentGroupId, file_id: currentFileId});
              } else if (!this.getActiveStyle.grouped_items || !Object.keys(this.getActiveStyle.grouped_items).length) {
                this.$store.dispatch('setActiveFile', null);
                this.$router.$updateQueryParams({file_id: '', gallery_group_id: ''});
              } else  {
                const currentFileId = this.getActiveStyle.grouped_items[this.$route.query.gallery_group_id][0].id
                const currentFile = items.find(file => +file.id === +currentFileId)
                if (activeFile.id === this.getActiveFile.id) {
                  this.$store.dispatch('setActiveFile', {...currentFile, loading: true});
                }
                this.$router.$updateQueryParams({file_id: currentFile.id});
              }

            } catch (error) {
              console.error(error);
            } finally {
              const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
              this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.getProject.id);
              this.$store.dispatch('setProject', project.data);
              this.$store.dispatch('setDesignModules', project.data.modules);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
    async downloadFile() {
      if (this.fileLoading) return;
      this.fileLoading = true;

      const activeFile = this.file || this.getActiveFile;

      const image = await fetch(activeFile.file.download_url || activeFile.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = activeFile.file.download_file_name || activeFile.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    updateStatus(status) {
      const activeFile = this.file || this.getActiveFile;
      if (status === 'archive' && !this.getPermissionByModule('can-archive-design') || activeFile.loading) return;

      activeFile.loading = true;

      const items = this.getActiveStyle.items.map(file => file.id === activeFile.id ? {...file, loading: true} : file);
      this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

      this.$api.projectModuleDesign.updateStatus(this.$route.params.project_id, this.$route.query.module_id, activeFile.id, status)
        .then(res => {
          const items = this.getActiveStyle.items.map(file => file.id === res.data.id ? {
            ...res.data,
            loading: false
          } : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
          if (activeFile && activeFile.id === this.getActiveFile.id) {
            this.$store.dispatch('setActiveFile', {...res.data, loading: false});
          }
        })
        .catch(err => {
          activeFile.loading = false;
          const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
          console.error(err);
        })
    },
  }
}
</script>
